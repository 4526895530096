import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import playIcon from '../../res/images/play_arrow.png'
import Back from '../../res/images/back-icon.svg'
import AFN_Logo from '../../res/images/afn-go-logo.svg'
import AFNGo from '../../res/images/afn-go-logo.svg'
import { useAuth, useTrack } from '../common/context';
import PauseIcon from '../../res/images/pause-icon.svg';
import { player } from '../common/player';
import moment from 'moment';

const DVIDS = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const podcasts = [...(location?.state?.podcasts || [])].sort((a, b) => moment(b?.date).diff(moment(a?.date)));
    const {onSetSelectedTrack, onSetPlayerState, selectedTrack, playerState} = useTrack()
    const {onRadioChange, onSetTrackDetails } = useAuth()
    // console.log('podcasts:', podcasts)

    const onPlayPauseTrack = (item) => {
        /// Reset Radio player ///
        player.stop();
        onSetTrackDetails({});
        onRadioChange({item: null,playState:false})
        /// Reset Radio player ///

        if(selectedTrack?.id == item?.id && playerState == "PLAY"){
            onSetPlayerState('STOP')
        }else{
            onSetSelectedTrack({
                id: item?.id,
                thumbnail: item?.thumbnail,
                title: item?.title,
                description: item?.description,
                file: item?.file,
            })
            onSetPlayerState('PLAY')
        }
    }

    return (
        <>
            <div>
                <div className="sidebar-overlay"></div>
                {/* <SideBar /> */}
                <section className="afn-page-body afn-full-page-body list-body">
                    <div className="container-fluid">
                        <Link className='radio-header-logo' to='/'>
                            <img src={AFN_Logo} alt="minimize" class="img-fluid" />
                        </Link>

                        <>
                            <img onClick={() => navigate(-1)} src={Back} alt="minimize" class="img-fluid minimize mobile-none" />

                            <div className='desktop-none header-mobile-menu'>
                                <img onClick={() => navigate(-1)} src={Back} alt="minimize" class="img-fluid minimize " />
                                {/* <img src={SearchIcon} alt="minimize" class="img-fluid minimize " /> */}
                                <div className="page-head">
                                </div>
                            </div>
                            
                            <h3 class="list-title mobile-none stationHeadingText">Podcast</h3>

                            <div className='radio-list-wrapper desktop-none'>
                                <h3 class="list-title stationHeadingText">Podcast</h3>
                                {podcasts?.length > 0 ?
                                    <>
                                        {podcasts?.map((item, index) => {
                                            return (
                                                <div className='radio-detail-box'>
                                                    <div className='station-imgBox' style={{flex: 0.15, maxHeight: 60, maxWidth: 60}}>
                                                        <img src={item?.thumbnail || AFNGo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                    </div>
                                                    <div className='radio-detail-contentBox' style={{flex: 0.8}}>
                                                        <h3 style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1,  /* number of lines to show */   lineClamp: 2,  WebkitBoxOrient: 'vertical',}}>{item?.title} </h3>
                                                        <p style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1,  /* number of lines to show */   lineClamp: 2,  WebkitBoxOrient: 'vertical',}}>{item?.date && `${moment(item?.date).format('DD/MM/YY')} - `}{`${item?.description?.replace(/\n/g, '. ')}`}</p>
                                                    </div>
                                                    <img src={ (selectedTrack?.id == item?.id && playerState == "PLAY") ? PauseIcon : playIcon} alt=""  style={{flex: 0.05}} className='hoverAbleCol' onClick={()=> onPlayPauseTrack(item)}/>
                                                </div>
                                            )
                                        })}
                                    </>
                                    :
                                    <span className='NotFountText'>Episode Not Found</span>
                                }
                                
                            </div>
                            <div className='row radio-list-row mobile-none'>
                                {podcasts?.length > 0 ?
                                    <>
                                        <div className='col-lg-7 mobile-none'>
                                            <div className="listBox-header">
                                                <div className='row'>
                                                    <div className='col-lg-2 col-md-2'>

                                                    </div>
                                                    <div className='col-lg-2 col-md-2'>
                                                        <div class="list-left-textBox">
                                                            Episode
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-7 col-md-7'>
                                                        <div class="list-middle-textBox">
                                                            Description
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='clearfix'></div>

                                        {podcasts?.map((item, index) => (
                                            <>
                                                <div className='col-lg-7' key={index}>
                                                    <div className="listBox-card">
                                                        <div className='row align-items-center'>
                                                            <div className='col-lg-2 col-md-2'>
                                                                <div className='stationImg'>
                                                                    <img src={item?.thumbnail || AFNGo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-2 col-md-2'>
                                                                <div style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2,  /* number of lines to show */   lineClamp: 2,  WebkitBoxOrient: 'vertical',}} class="list-left-textBox">
                                                                    {item?.title}
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-7 col-md-7'>
                                                                <div style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2,  /* number of lines to show */   lineClamp: 2,  WebkitBoxOrient: 'vertical',}} class="list-middle-textBox">
                                                                    {item?.date && `${moment(item?.date).format('DD/MM/YY')} - `}{`${item?.description?.replace(/\n/g, '. ')}`}
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-1 col-md-1'>
                                                                <div class="list-right-textBox">
                                                                    {/* <img src={playIcon} alt="" className='hoverAbleCol' onClick={()=> onPlayPauseTrack(item)}/> */}
                                                                    <img src={ (selectedTrack?.id == item?.id && playerState == "PLAY") ? PauseIcon : playIcon} alt=""  style={{flex: 0.05}} className='hoverAbleCol' onClick={()=> onPlayPauseTrack(item)}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='clearfix'></div>
                                            </>
                                        ))}
                                    </>
                                    :
                                    <span className='NotFountText'>Episode Not Found</span>
                                }
                            </div>
                        </>

                    </div>
                </section>
            </div>
        </>
    );
}

export default DVIDS;
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Back from '../../res/images/back-icon.svg'
import AFN_Logo from '../../res/images/afn-go-logo.svg'
import AFNGo from '../../res/images/afn-go-logo.svg'
import axios from 'axios';
import { useAuth, useTrack } from '../common/context';
import PauseIcon from '../../res/images/pause-icon.svg';
import playIcon from '../../res/images/play_arrow.png'
import { player } from '../common/player';
import { Bars } from 'react-loader-spinner';
import moment from 'moment';


const Podcast = () => {
    const navigate = useNavigate();
    const [omnyPrograms, setOmnyPrograms] = useState([])
    const {onSetSelectedTrack, onSetPlayerState, selectedTrack, playerState} = useTrack()
    const {onRadioChange, onSetTrackDetails } = useAuth()

    const [DVIDSPodcast, setDVIDSPodcast] = useState([])
    const [isDVIDSPodcastLoading, setIsDVIDSPodcastLoading] = useState(false)
    const [activeTab, setActveTab] = useState('Heard on AFN')
    const tabs = ['Heard on AFN', 'Military'];

    useEffect(() => {
        function thanksgiving(theyear){
            var nov1stDay = new Date(theyear, 10, 1, 0, 0, 0, 0);
            var dayOfWeek = nov1stDay.getDay();
            var tgDayOfMonth = nov1stDay.getDate() - (dayOfWeek - 4) + 21;
            return 10 + '.' + tgDayOfMonth;
        }
        console.log(thanksgiving(2023));
        
        setIsDVIDSPodcastLoading(true)
        axios.get(`https://cms.afngo.net/api/v1/dvids/podcasts`)
        .then((res) => {
            // console.log('dvids res.data: ',res)
            let units = new Array();
            res?.data?.audio.forEach((item) => {
                let index = units.findIndex((unit) => (unit?.unit_name === item?.unit_name))
                if(index === -1 && item?.unit_name !== undefined){
                units.push({
                    unit_name: item.unit_name,
                    date: item.date,
                    description: item.description,
                    thumbnail: item.thumbnail,
                    podcasts: [{
                        id: item.id,
                        thumbnail: item.thumbnail,
                        title: item.title,
                        date: item.date,
                        description: item.description,
                        file: item.file,
                    }]
                });
                }
                else{
                units[index] = {
                    ...units[index],
                    podcasts: [
                    ...(units[index]?.podcasts || []),
                    {
                        id: item.id,
                        thumbnail: item.thumbnail,
                        title: item.title,
                        date: item.date,
                        description: item.description,
                        file: item.file,
                    }
                    ],
                }
                }
            });
            setDVIDSPodcast([...(units|| [])].sort((a, b) => moment(b?.date).diff(moment(a?.date))))
            // console.log('units', units)
            setIsDVIDSPodcastLoading(false)
        })
        .catch((err) => {
            console.log(err)
            setIsDVIDSPodcastLoading(false)
        })

        axios.get(`https://omny.fm/api/orgs/58771630-df14-4cd9-95b0-ade300f713f0/programs`)
        .then((res) => {
            // console.log('Omny res.data: ',res)
            setOmnyPrograms([...(res?.data?.Programs|| [])].sort((a, b) => moment(b?.ModifiedAtUtc).diff(moment(a?.ModifiedAtUtc))))
        })
        .catch((err) => {
            console.log(err)
        })
        
    }, [])

    const onPlayPauseTrack = (item) => {
        /// Reset Radio player ///
        player.stop();
        onSetTrackDetails({});
        onRadioChange({item: null,playState:false})
        /// Reset Radio player ///

        if(selectedTrack?.id == item?.id && playerState == "PLAY"){
            onSetPlayerState('STOP')
        }else{
            onSetSelectedTrack(item)
            onSetPlayerState('PLAY')
        }
    }

    return (
        <>
            <div>
                <div className="sidebar-overlay"></div>
                {/* <SideBar /> */}
                <section className="afn-page-body afn-full-page-body list-body">
                    <div className="container-fluid">
                        <Link className='radio-header-logo' to='/'>
                            <img src={AFN_Logo} alt="minimize" class="img-fluid" />
                        </Link>
                        
                        <>
                            <img onClick={() => navigate(-1)} src={Back} alt="minimize" class="img-fluid minimize mobile-none" />

                            <div className='desktop-none header-mobile-menu'>
                                <img onClick={() => navigate(-1)} src={Back} alt="minimize" class="img-fluid minimize " />
                                {/* <img src={SearchIcon} alt="minimize" class="img-fluid minimize " /> */}
                                <div className="page-head">
                                </div>
                            </div>
                            <h3 class="list-title mobile-none stationHeadingText">Podcast</h3>

                            <div className='radio-list-wrapper desktop-none'>
                                <h3 class="list-title stationHeadingText">Podcast</h3>
                                <div style={{height: 60, display: 'flex', marginBottom: 12}} className='col-lg-7 hoverAbleCol'>
                                    {tabs?.map((item, index) => (
                                        <div style={{backgroundColor: activeTab == item ? '#1ab1fe' : '#020328', height: '100%', flex: 1, borderTopLeftRadius: index == 0 ? 8 : 0, borderBottomLeftRadius: index == 0 ? 8 : 0, borderTopRightRadius: index == tabs?.length - 1 ? 8 : 0, borderBottomRightRadius: index == tabs?.length - 1 ? 8 : 0}} onClick={() => setActveTab(item)}>
                                            <h4 className="text-center" style={{ marginTop: 18, color: 'white', fontWeight: 'bold'}}>{item}</h4>
                                        </div>
                                    ))}
                                </div>

                                {activeTab == 'Heard on AFN' ? 
                                    <>
                                        {omnyPrograms?.length > 0 ?
                                            <>
                                                {omnyPrograms?.map((item, index) => {
                                                    return (
                                                        <div className='radio-detail-box' onClick={(e) => navigate(`/afnpodcast/Heard-on-AFN/${item?.Id}`, {state: {program: item}})}>
                                                            <div className='station-imgBox' style={{flex: 0.15, maxHeight: 60, maxWidth: 60}}>
                                                                <img src={item?.ArtworkUrl || AFNGo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                            </div>
                                                            <div className='radio-detail-contentBox' style={{flex: 0.85}}>
                                                                <h3>{item?.Name} </h3>
                                                                <p style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1,  /* number of lines to show */   lineClamp: 1,  WebkitBoxOrient: 'vertical',}}>
                                                                    {/* {item?.ModifiedAtUtc && `${moment(item?.ModifiedAtUtc).format('DD/MM/YY')} - `} */}
                                                                    {`${item?.Description?.replace(/\n/g, '. ')}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                            :
                                            <span className='NotFountText'>Podcast Not Found</span>
                                        }
                                    </>
                                    :
                                    <>
                                        {DVIDSPodcast?.length > 0 ?
                                            <>
                                                {DVIDSPodcast?.map((item, index) => {
                                                    return (
                                                        <div className='radio-detail-box' onClick={(e) => navigate(`/afnpodcast/military/${encodeURIComponent(item?.unit_name)}`, {state: {podcasts: item?.podcasts}})}>
                                                            <div className='station-imgBox' style={{flex: 0.15, maxHeight: 60, maxWidth: 60}}>
                                                                <img src={item?.thumbnail || AFNGo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                            </div>
                                                            <div className='radio-detail-contentBox' style={{flex: 0.85}}>
                                                                <h3 style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1,  /* number of lines to show */   lineClamp: 1,  WebkitBoxOrient: 'vertical',}}>{item?.unit_name} </h3>
                                                                <p style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1,  /* number of lines to show */   lineClamp: 1,  WebkitBoxOrient: 'vertical',}}>
                                                                    {
                                                                        // item?.date && `${moment(item?.date).format('DD/MM/YY')} - `
                                                                    }
                                                                    {`${item?.description?.replace(/\n/g, '. ')}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                            :
                                            <>
                                                {isDVIDSPodcastLoading ? 
                                                    <div style={{justifyContent: 'center', display: 'flex', marginTop: 40}}>
                                                        <Bars color="#00BFFF" height={80} width={80} />
                                                    </div>
                                                    :
                                                    <span className='NotFountText'>Podcast Not Found</span>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                
                            </div>
                            <div className='row radio-list-row mobile-none'>

                            <div style={{height: 60, display: 'flex', marginBottom: 12}} className='col-lg-7 mobile-none hoverAbleCol'>
                                {tabs?.map((item, index) => (
                                    <div style={{backgroundColor: activeTab == item ? '#1ab1fe' : '#020328', height: '100%', flex: 1, borderTopLeftRadius: index == 0 ? 8 : 0, borderBottomLeftRadius: index == 0 ? 8 : 0, borderTopRightRadius: index == tabs?.length - 1 ? 8 : 0, borderBottomRightRadius: index == tabs?.length - 1 ? 8 : 0}} onClick={() => setActveTab(item)}>
                                        <h4 className="text-center" style={{ marginTop: 18, color: 'white', fontWeight: 'bold'}}>{item}</h4>
                                    </div>
                                ))}
                            </div>
                            {activeTab == 'Heard on AFN' ? 
                                <>
                                    {omnyPrograms?.length > 0 ?
                                        <>
                                            <div className='col-lg-7 mobile-none'>
                                                <div className="listBox-header">
                                                    <div className='row'>
                                                        <div className='col-lg-2 col-md-2'>

                                                        </div>
                                                        <div className='col-lg-2 col-md-2'>
                                                            <div class="list-left-textBox">
                                                                Podcast
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-7 col-md-7'>
                                                            <div class="list-middle-textBox">
                                                                Description
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='clearfix'></div>

                                            {omnyPrograms?.map((item, index) => (
                                                <>
                                                    <div className='col-lg-7 hoverAbleCol' key={index} onClick={(e) => navigate(`/afnpodcast/Heard-on-AFN/${item?.Id}`, {state: {program: item}})}>
                                                        <div className="listBox-card">
                                                            <div className='row align-items-center'>
                                                                <div className='col-lg-2 col-md-2'>
                                                                    <div className='stationImg'>
                                                                        <img src={item?.ArtworkUrl || AFNGo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-2'>
                                                                    <div class="list-left-textBox">
                                                                        {item?.Name}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-8 col-md-8'>
                                                                    <div class="list-middle-textBox">
                                                                        {
                                                                            // item?.ModifiedAtUtc && `${moment(item?.ModifiedAtUtc).format('DD/MM/YY')} - `
                                                                        }
                                                                        {`${item?.Description?.replace(/\n/g, '. ')}`}
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-lg-1 col-md-1'>
                                                                    <div class="list-right-textBox">
                                                                        <img src={playIcon} alt="" />
                                                                    </div>
                                                                </div> */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </>
                                            ))}
                                        </>
                                        :
                                        <span className='NotFountText'>Podcast Not Found</span>
                                    }
                                </>
                                :
                                <>
                                    {DVIDSPodcast?.length > 0 ?
                                        <>
                                            <div className='col-lg-7 mobile-none'>
                                                <div className="listBox-header">
                                                    <div className='row'>
                                                        <div className='col-lg-2 col-md-2'>

                                                        </div>
                                                        <div className='col-lg-2 col-md-2'>
                                                            <div class="list-left-textBox">
                                                                Podcast
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-7 col-md-7'>
                                                            <div class="list-middle-textBox">
                                                                Description
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='clearfix'></div>

                                            {DVIDSPodcast?.map((item, index) => (
                                                <>
                                                    <div className='col-lg-7' key={index} onClick={(e) => navigate(`/afnpodcast/military/${encodeURIComponent(item?.unit_name)}`, {state: {podcasts: item?.podcasts}})}>
                                                        <div className="listBox-card">
                                                            <div className='row align-items-center'>
                                                                <div className='col-lg-2 col-md-2'>
                                                                    <div className='stationImg'>
                                                                        <img src={item?.thumbnail || AFNGo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-2'>
                                                                    <div style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3,  /* number of lines to show */   lineClamp: 3,  WebkitBoxOrient: 'vertical',}} class="list-left-textBox">
                                                                        {item?.unit_name}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-8 col-md-8'>
                                                                    <div style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3,  /* number of lines to show */   lineClamp: 3,  WebkitBoxOrient: 'vertical',}} class="list-middle-textBox">
                                                                        {
                                                                            // item?.date && `${moment(item?.date).format('DD/MM/YY')} - `
                                                                        }
                                                                        {`${item?.description?.replace(/\n/g, '. ')}`}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </>
                                            ))}
                                        </>
                                        :
                                        <>
                                            {isDVIDSPodcastLoading ? 
                                                <div style={{justifyContent: 'center', display: 'flex', marginTop: 40}}>
                                                    <Bars color="#00BFFF" height={80} width={80} />
                                                </div>
                                                :
                                                <span className='NotFountText'>Podcast Not Found</span>
                                            }
                                        </>
                                    }
                                </>
                            }
                                
                            </div>
                        </>

                    </div>
                </section>
            </div>
        </>
    );
}

export default Podcast;
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate,useLocation, Link } from 'react-router-dom';
import AFN_Logo from '../res/images/afn-go-logo.svg'
import Back from '../res/images/back-icon.svg'
import SearchIcon from '../res/images/search-icon.svg';
import RadioListData from '../radio_station.js'
import { imgList } from "../const";
import Footer from './common/footer';


const RadioLocations = () => {
    const navigate = useNavigate();
    const {pathname} = useLocation()
    const [showInput, setShowInput] = useState(false)
    const [radioList, setRadioList] = useState([])
    const [locationList,setLocationList]= useState([])
    const [region,setRegion] = useState('')

    useEffect(() => {
        if (region) {
            var result = RadioListData.station.find(obj => {
                return obj.region === region
            })
            var radioCity=result.list.filter((value) => value.city)
            setRadioList(radioCity)
            setLocationList(radioCity)
        }

    }, [region])

    useEffect(()=>{
        if(pathname==='/afneurope'){
            setRegion('Europe')
        }
        if(pathname==='/afnpacific'){
            setRegion('Pacific')
        }
    },[pathname])

    const handleSearch = (e) => {
        var serachRadio = locationList.filter((item) => item.actual.toLowerCase().includes(e.target.value.toLowerCase()))
        setRadioList(serachRadio)
    }

    const onPressLocation = (item) => {
        sessionStorage.setItem('location', item.actual)
        navigate(`${pathname}/${item.actual}`)
    }

    return (
        <>
            <div>
                <div className="sidebar-overlay"></div>
                {/* <SideBar /> */}
                <section className="afn-page-body afn-full-page-body list-body">
                    <div className="container-fluid">
                        <Link className='radio-header-logo' to='/'>
                            <img src={AFN_Logo} alt="minimize" class="img-fluid" />
                        </Link>
                    </div>
                    <h2 class="player-title mobile-none text-uppercase">AFN GO {region}</h2>
                    <div className='desktop-none header-mobile-menu location-menu-header'>
                        <img onClick={() => navigate('/')} src={Back} alt="minimize" class="img-fluid minimize " />
                        <h2 class="player-title">Radio Stations</h2>
                        {/* <img src={SearchIcon} alt="minimize" class="img-fluid minimize " /> */}
                        <div className="page-head">
                            <div className={showInput ? "input-group-collapsed input-group" : 'input-group'}>
                                <span className="input-group-text" onClick={() => setShowInput(!showInput)}>
                                    <img src={SearchIcon} alt="ic" className="img-fluid" />
                                </span>
                                <input type="text" onChange={(e) => handleSearch(e)} className="form-control" placeholder="Search" />
                            </div>
                        </div>
                    </div>
                    <div className="page-head mobile-none">
                        <div className="input-group">
                            <span className="input-group-text">
                                <img src={SearchIcon} alt="ic" className="img-fluid" />
                            </span>
                            <input type="text" onChange={(e) => handleSearch(e)} className="form-control" placeholder="Search" />
                        </div>
                    </div>
                    <h3 class="list-title mobile-none chooseLocation">Choose Location</h3>
                    <div class="row justify-content-center">
                        <div class={pathname==='/afneurope'?"col-lg-6":"col-lg-5"}>
                            <div class="station-grid-box">
                                {radioList?.length>0?
                                <>
                                {radioList?.map((item, index) => {
                                    return (
                                        <div class={pathname==='/afneurope'?"europe-imgContainer":"pacific-imgContainer"} onClick={() => onPressLocation(item)}>
                                            <img src={imgList.filter((el) => el.id == item.id)[0]?.name} alt="minimize" class="img-fluid afn-thumbnail" />
                                        </div>)
                                })}
                                </>
                                :
                                <span className='NotFountText'>Station Not Found</span>
                            }
                            </div>
                        </div>
                    </div>

                </section>
            </div>

           {/* <Footer/> */}
        </>
    )
}
export default RadioLocations
import logo from './logo.svg';
import Navigation from './navigation/navigation';
import 'bootstrap/dist/css/bootstrap.min.css';

import { getAnalytics, logEvent } from "firebase/analytics";




import './App.css';

function App() {
//   const analytics = getAnalytics();
// logEvent(analytics, 'notification_received');
  return (

    <div className="App">
      <Navigation />
      <script type="text/javascript" src="https://sdk.listenlive.co/web/2.9/td-sdk.min.js"></script>
    </div>

  );
}
export default App;

import React, { useEffect, useState, createContext, useContext } from 'react';


const AuthContext = createContext();
const TrackContext = createContext();

export function AuthProvider(props) {
    const [ radio, setRadio] = useState();
    const [ trackDetails, setTrackDetails] = useState();
    const [ playing, setPlaying ] = useState(false)
    
    const onRadioChange=({item,playState})=>{
        setRadio(item)
        setPlaying(playState)
    }

    const onSetTrackDetails=(trackDetails)=>{
        setTrackDetails(trackDetails)
    }
   
    return <AuthContext.Provider value={{radio, onRadioChange,playing, trackDetails, onSetTrackDetails}} {...props} />;
}

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
}

export function TrackProvider(props) {
    const [ selectedTrack, setSelectedTrack ] = useState({})
    const [ playerState, setPlayerState ] = useState(false)
    
    const onSetSelectedTrack=(track)=>{
        setSelectedTrack(track)
    }
    const onSetPlayerState=(track)=>{
        setPlayerState(track)
    }
   
    return <TrackContext.Provider value={{onSetSelectedTrack, selectedTrack, onSetPlayerState, playerState}} {...props} />;
}

export function useTrack() {
    const context = useContext(TrackContext);
    if (context === undefined) {
        throw new Error('useTrack must be used within a TrackProvider');
    }
    return context;
}



// const { radio } = useAuth();
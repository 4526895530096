import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import AFN_Logo from '../res/images/afn-new-logo.svg';
import appleStoreIcon from '../res/images/app-store-icon.svg'
import playStoreIcon from '../res/images/play-store-icon.svg'


const Home = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const region = sessionStorage.getItem('region_name') ? sessionStorage.getItem('region_name') : ''
  }, [])

  const handleSelect = (value) => {
    sessionStorage.setItem('region_name', value)
    navigate(`/afn${value.toLowerCase()}`)
  }
  // const handleSelectNews = (value) => {
  //   sessionStorage.setItem('region_name', value)
  //   navigate(`radioList`)
  // }

  return (
    <>
      <section className="splash-bg">
        <div className="container">
          {/* <div className="navbar-nav radio-list radio-list-nav">
          <div className="page-heading">
            
            <select class="form-select selectRegion" value={selectRegion} onChange={(e) =>handleSelect(e) } aria-label="Default select example">
              <option value=''>Select  Region </option>
              {RadioListData.station.map((element) => (
                <option value={element.region}>{element.region}</option>
              ))}
            </select>
          </div>
          <Link className="nav-item nav-link" to="/radioList" >RadioList</Link>
        </div> */}
          <div className='row justify-content-center'>
            <div className='col-lg-12'>
              <div className="afn-logo button-logo-box">
                <img src={AFN_Logo} alt="logo" className="img-fluid" />
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='homeTextBox'>
                <p>
                  AFN Go is the American Forces Network’s streaming audio service available to the authorized audience serving outside of the United States, its territories, and possessions. The service is available via an internet-connected computer at AFNGO.net or via the free AFN Go app, available in the Apple and Google app stores.</p>
                <p>
                  The service provides listeners with a wide variety of music from the major, popular genres as well as the best in talk and news radio. Users can also select and listen to their favorite local AFN station and obtain push notifications about important issues impacting their health, safety, wellbeing, and situational awareness.
                </p>
                <p>
                  Each AFN station has its own channel on the app along with programming originating directly from the AFN Broadcast Center in California.
                </p>
                <p>
                This app is for listening to AFN audio - it does not provide tv schedules. AFN tv schedules are available at <a href="https://v3.myafn.dodmedia.osd.mil" target="_blank" class="tvScheduleLink"> https://v3.myafn.dodmedia.osd.mil </a>
                </p>
              </div>
            </div>
            <div className='clearfix'></div>
            <div className='col-lg-8'>
              <div className="button-container">
                <div>
                  <img src={AFN_Logo} alt="logo" className="img-fluid" />
                  <button className='station-btn' onClick={() => handleSelect('Europe')}>
                    Europe Stations
                  </button>
                </div>
                <div>
                  <img src={AFN_Logo} alt="logo" className="img-fluid" />
                  <button className='station-btn' onClick={() => handleSelect('Pacific')}>
                    Asia-Pacific Stations
                  </button>
                </div>
              </div>
            </div>
            <div className='clearfix'></div>
            <div className='col-lg-8'>
              <div className='storeIconBox'>
                <div className='appleStoreIcon'>
                  <a href='https://apps.apple.com/us/app/afn-go/id1614081529' target="_blank">
                    <img src={appleStoreIcon} alt="logo" className="img-fluid" />
                  </a>
                </div>
                <div className='playStoreIcon'>
                  <a href='https://play.google.com/store/apps/details?id=com.afn360' target="_blank">
                    <img src={playStoreIcon} alt="logo" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="afn-logo button-logo-box">
            <img src={AFN_Logo} alt="logo" className="img-fluid" />
          </div> */}
          {/* <div className="button-container">
            <button className='station-btn' onClick={()=>handleSelectNews('News & Sports')}>
            News & Sports
            </button>
          </div> */}
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
}

export default Home;
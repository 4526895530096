import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../res/images/afn-logo.svg';
import Back from '../res/images/back-icon.svg'
import AFN_Logo from '../res/images/afn-go-logo.svg'
import Footer from './common/footer';
import { Modal } from "react-bootstrap";
import axios from 'axios';

const TearmsAndConditions = (props) => {
    const navigate = useNavigate();
    const handleClose = ()=>{
        props.setShow(false)
    }
    const [termsAndServices, setTermsAndServices] = useState([])

    useEffect(() => {
      axios.get(`https://cms.afngo.net/api/v1/terms_and_conditions`)
      .then((res) => {
          // console.log('res.data: ', res?.data)
          setTermsAndServices(res?.data?.content)
      })
      .catch((err) => {
          console.log(err)
      })
    }, [])

    return (

        <>
        <Modal centered size="xl" show={props.show} onHide={handleClose}>
        
         <section class="afn-page-body afn-full-page-body">
                <div class="afn-player-wrapper">
                    <div className='radio-header-logo'>
                        <img src={AFN_Logo} alt="minimize" class="img-fluid" />
                    </div>
                    {/* <div className='radio-header-back mobile-none'>
                        <img onClick={() => navigate('/')} src={Back} alt="minimize" class="img-fluid" />
                    </div> */}
                    <div className="terms-heading mobile-none modalCloseBox">
                        Terms & Conditions
                        <span onClick={handleClose}>X</span>
                    </div>
                    <div className='desktop-none terms-header-mobile-menu'>
                        <img onClick={() => navigate('/')} src={Back} alt="minimize" class="img-fluid terms-back " />
                        <h2 class="player-title">Terms of Service</h2>
                    </div>
                    {/* <img src={Minimize} alt="minimize" class="img-fluid minimize" /> */}
                    <div class="terms-content-box">
                        <div
                            dangerouslySetInnerHTML={{__html: termsAndServices}}
                        />
                    </div>

                </div>
            </section>
           
            </Modal>
        </>

    )
}
export default TearmsAndConditions 
import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Back from "../../res/images/back-icon.svg";
import AFN_Logo from "../../res/images/afn-go-logo.svg";
import _ from "lodash";

const RadioScheduleDetails = () => {
  const location = useLocation()
  const navigate = useNavigate();

  const schedule = location?.state?.schedule?.data
  let data = Object.keys(schedule);
  var filterData = _.remove(data, function (c) {
    return c !== 'channel_name';
  });

  var finalData = _.remove(filterData, function (c) {
    return c !== 'channel_url';
  });
  // console.log('finalData: ', finalData);

  const getName = (name) => {
    return name
    ? name === "Today's Best Country"
      ? 'Country'
      : name === 'HOT AC'
      ? 'The Blend'
      : name === 'PowerTalk'
      ? 'Power Talk'
      : name
    : ''
  }

  return (
    <>
      <div>
        <div className="sidebar-overlay"></div>
        {/* <SideBar /> */}
        <section className="afn-page-body afn-full-page-body list-body">
          <div className="container-fluid">
            <Link className="radio-header-logo" to="/">
              <img src={AFN_Logo} alt="minimize" class="img-fluid" />
            </Link>

            <>
              <img
                onClick={() => navigate(-1)}
                src={Back}
                alt="minimize"
                class="img-fluid minimize mobile-none"
              />

              <div className="desktop-none header-mobile-menu">
                <img
                  onClick={() => navigate(-1)}
                  src={Back}
                  alt="minimize"
                  class="img-fluid minimize "
                />
                {/* <img src={SearchIcon} alt="minimize" class="img-fluid minimize " /> */}
                <div className="page-head"></div>
              </div>

              <h3 class="list-title stationHeadingText">
                {getName(schedule?.channel_name)}
              </h3>

              <div className="radio-list-wrapper desktop-none">
                <>
                  {finalData?.map((item, index) => {
                    return (
                      <div key={item}>
                        <div style={{marginTop: 32}}/>
                        <h3 class="list-title stationHeadingText">
                          {item?.replace(/_/g, " ")}
                        </h3>
  
                        <div style={{display: 'flex', marginBottom: 8}}>
                          <div
                            className="radio-detail-contentBox"
                            style={{ flex: 0.4 }}
                          >
                            <h3
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 1,
                                /* number of lines to show */ lineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              Time
                            </h3>
                          </div>
                          <div
                            className="radio-detail-contentBox"
                            style={{ flex: 0.6 }}
                          >
                            <h3
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 1,
                                /* number of lines to show */ lineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                textAlign: 'end'
                              }}
                            >
                              Station
                            </h3>
                          </div>
                        </div>
  
                        {schedule?.[item]?.map((_item, index) => (
                          <div key={_item} className="radio-detail-box">
                            <div
                              className="radio-detail-contentBox"
                              style={{ flex: 0.4 }}
                            >
                              <h3
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  /* number of lines to show */ lineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {_item?.replace(/_/g, " ")?.substring(0, _item.indexOf(' '))}
                              </h3>
                            </div>
                            <div
                              className="radio-detail-contentBox"
                              style={{ flex: 0.6 }}
                            >
                              <h3
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  /* number of lines to show */ lineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  textAlign: 'end'
                                }}
                              >
                                {_item?.replace(/_/g, " ")?.substring(_item.indexOf(' ') + 1)}
                              </h3>
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </>
              </div>
              <div className="row radio-list-row mobile-none">
                <>
                  {finalData?.map((item, index) => (
                    <div key={item} className='col-lg-7'>
                      <div style={{marginTop: 32}}/>
                      <h3 class="list-title stationHeadingText">
                        {item?.replace(/_/g, " ")}
                      </h3>

                      <div>
                          <div className="listBox-header">
                              <div className='row'>
                                  <div className='col-lg-6 col-md-6'>
                                      <div class="list-left-textBox">
                                          Time
                                      </div>
                                  </div>
                                  <div className='col-lg-6 col-md-6'>
                                      <div class="list-middle-textBox" style={{textAlign: 'end'}}>
                                          Program
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                      <div className='clearfix'></div>

                      {schedule?.[item]?.map((_item, index) => (
                        <div key={_item}>
                          <div className="listBox-card">
                            <div className='row'>
                              <div className='col-lg-6 col-md-6'>
                                <div class="list-left-textBox">
                                  {_item?.replace(/_/g, " ")?.substring(0, _item.indexOf(' '))}
                                </div>
                              </div>
                              <div className='col-lg-6 col-md-6'>
                                <div class="list-middle-textBox" style={{textAlign: 'end'}}>
                                  {_item?.replace(/_/g, " ")?.substring(_item.indexOf(' ') + 1)}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"/>
                        </div>
                      ))}
                    </div>
                  ))}
                </>
              </div>
            </>
          </div>
        </section>
      </div>
    </>
  );
};

export default RadioScheduleDetails;

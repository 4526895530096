import moment from "moment";
import RadioListData from './radio_station.json'

const todaysDate = moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY");
const jan1st2024 = moment("01/01/2024", "DD/MM/YYYY");
// const startDate = moment("22/11/2023", "DD/MM/YYYY");
// const endDate = moment("02/01/2024", "DD/MM/YYYY");


// if(todaysDate.isBetween(startDate, endDate) || todaysDate.isSame(startDate) || todaysDate.isSame(endDate)) {
//     RadioListData?.station?.[0]?.list?.push({
//         "id": 315,
//         "triton": "AFN_SEA",
//         "actual": "Holiday",
//         "description": "AFN Holiday"
//     })
//     RadioListData?.station?.[1]?.list?.push({
//         "id": 315,
//         "triton": "AFN_SEA",
//         "actual": "Holiday",
//         "description": "AFN Holiday"
//     })
// }

if((todaysDate.isAfter(jan1st2024) || todaysDate.isSame(jan1st2024)) && RadioListData?.station?.[2]?.list?.length > 0) {
    RadioListData.station[2].list = RadioListData?.station?.[2]?.list.filter(x=> x.id !== 41);
}

const checkHoliday = (dateToCheck) => {
    if(moment().month() === 0 && moment().date() <= 2) {                                                    //checks if the current month is January and date is smaller or equal to 2 of any year
        return true;
    } else {
        const currentMonth1stDate = moment("01/11/"+moment().year(), "DD/MM/YYYY");                         //get 1st November of current year
        let tempDate = currentMonth1stDate, i = 0;
        while(i<4 && !tempDate.isAfter(moment("01/12/"+moment().year(), "DD/MM/YYYY"))) {
            if(tempDate.isoWeekday() === 4) i++;                                                            //checks if Thursday
            if(i === 4) {                                                                                   //checks if the day is Thursday
                break; 
            }
            tempDate = tempDate.add(1,'days');
        }
        const startDate = tempDate.add(-3,'days');                                                          //gets the date of Monday of 4th week of November of current year
        const endDate = moment("02/01/"+ (moment().year() + 1), "DD/MM/YYYY");                              //gets 2nd January of next year
        if(dateToCheck.isBetween(startDate, endDate) || dateToCheck.isSame(startDate) || dateToCheck.isSame(endDate)) {
            return true
        }
    }
}

if(checkHoliday(todaysDate)) {
    RadioListData?.station?.[0]?.list?.push({
        "id": 315,
        "triton": "AFN_SEA",
        "actual": "Holiday",
        "description": "AFN Holiday"
    })
    RadioListData?.station?.[1]?.list?.push({
        "id": 315,
        "triton": "AFN_SEA",
        "actual": "Holiday",
        "description": "AFN Holiday"
    })
}



export default RadioListData;
import Aviano from './res/images/station-images/Aviano.png';
import Bahrain from './res/images/station-images/Bahrain.png';
import Bavaria from './res/images/station-images/Bavaria.png';
import Benelux from './res/images/station-images/Benelux.png';
import Incirlik from './res/images/station-images/Incirlik.png';
import Kaiserslautern from './res/images/station-images/Kaiserslautern.png';
import Naples from './res/images/station-images/Naples.png';
import Rota from './res/images/station-images/Rota.png';
import Sigonella from './res/images/station-images/Sigonella.png';
import SoudaBay from './res/images/station-images/Souda-Bay.png';
import Spangdahlem from './res/images/station-images/Spangdahlem.png';
import Stuttgart from './res/images/station-images/Stuttgart.png';
import Vincenza from './res/images/station-images/Vicenza.png';
import Wiesbaden from './res/images/station-images/Wiesbaden.png';
import Daegu from './res/images/station-images/Daegu.png';
import Iwakuni from './res/images/station-images/Iwakuni.png';
import Kunsan from './res/images/station-images/Kunsan.png';
import Misawa from './res/images/station-images/Misawa.png';
import Okinawa from './res/images/station-images/Okinawa.png';
import Humphreys from './res/images/station-images/Humphreys.png';
import Sasebo from './res/images/station-images/Sasebo.png';
import Tokyo from './res/images/station-images/Tokyo.png';
import theVoicelogo from './res/images/station-images/theVoice_logo.png';
import PowerTalk from './res/images/station-images/Power-Talk.png';
import AFNFans from './res/images/station-images/AFN_Fans.png';
import AFN_Country from './res/images/station-images/AFN_Country.png';
import Freedom from './res/images/station-images/AFN_Freedom.png';
import Blend from './res/images/station-images/AFN-Blend.png';
import Joe from './res/images/station-images/AFN_Joe.png';
import Legacy from './res/images/station-images/AFN_Legacy.png';
import Gravity from './res/images/station-images/AFN_Gravity.png';
import Guantanamo from './res/images/station-images/Guantanamo-Bay.png';
import AFN_SEA_HOLIDAY from './res/images/station-images/AFN_SEA_HOLIDAY.png';

export const imgList = [
    { name: Aviano, id: 11 },
    { name: Bahrain, id: 12 },
    { name: Bavaria, id: 13 },
    { name: Benelux, id: 14 },
    { name: Guantanamo, id: 15 },
    { name: Incirlik, id: 16 },
    { name: Kaiserslautern, id: 17 },
    { name: Naples, id: 19 },
    { name: Rota, id: 110 },
    { name: Sigonella, id: 111 },
    { name: SoudaBay, id: 112 },
    { name: Spangdahlem, id: 113 },
    { name: Stuttgart, id: 114 },
    { name: Vincenza, id: 115 },
    { name: Wiesbaden, id: 116 },
    { name: Daegu, id: 21 },
    { name: Iwakuni, id: 22 },
    { name: Kunsan, id: 23 },
    { name: Misawa, id: 24 },
    { name: Okinawa, id: 25 },
    { name: Humphreys, id: 26 },
    { name: Sasebo, id: 27 },
    { name: Tokyo, id: 28 },
    { name: theVoicelogo, id: 42 },
    { name: PowerTalk, id: 41 },
    { name: AFNFans, id: 40 },
    { name: AFN_Country, id: 117 },
    { name: AFN_Country, id: 29 },
    { name: Freedom, id: 30 },
    { name: Freedom, id: 118 },
    { name: Blend, id: 120 },
    { name: Blend, id: 31 },
    { name: Joe, id: 32 },
    { name: Joe, id: 121 },
    { name: Legacy, id: 122 },
    { name: Legacy, id: 33 },
    { name: Gravity, id: 119},
    { name: AFN_SEA_HOLIDAY, id: 315}
];

export const csegids = {
    "Aviano": 1,
    "Bavaria": 2,
    "Benelux": 3,
    "Incirlik": 4,
    "Lajes": 5,
    "Kaiserslautern": 6,
    "Naples": 7,
    "Rota": 8,
    "Sigonella": 9,
    "Souda Bay": 10,
    "Spangdahlem": 11,
    "Stuttgart": 12,
    "United Kingdom": 13,
    "Vicenza": 14,
    "Wiesbaden": 15,
    "Bahrain": 16,
    "Guantanamo Bay": 17,
    "Daegu": 31,
    "Kunsan": 32,
    "Iwakuni": 33,
    "Misawa": 34,
    "Okinawa": 35,
    "Humphreys": 36,
    "Sasebo": 37,
    "Tokyo": 38,
  }
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Back from "../../res/images/back-icon.svg";
import AFN_Logo from "../../res/images/afn-go-logo.svg";
import _ from "lodash";
import SearchIcon from '../../res/images/search-icon.svg';
import axios from "axios";
import moment from "moment";
import Loader from "../../res/images/Loader.gif";

const ExchangeRate = () => {
  const { state } = useLocation()
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const [communityBankRates, setCommunityBankRates] = React.useState([]);
  const [otherRates, setOtherRates] = React.useState([]);

  useEffect(() => {
    setLoading(true)
    axios.post(`https://cms.afngo.net/api/v1/external_info/currency_rates_new`)
    .then((res) => {
      // console.log('res.data: ', res?.data)
      if (location.pathname?.toLowerCase()?.includes('/europe')) {
        setData(res?.data?.europe_exchange_rate?.data?.ExchangeRate)
      }
      if (location.pathname?.toLowerCase()?.includes('/pacific')) {
        setData(res?.data?.pacific_exchange_rate?.data?.ExchangeRate)
      }
      setLoading(false)
    })
    .catch((err) => {
      console.log(err)
      setData(null)
      setLoading(false)
      // alert('No data found')
    })
  }, [])

  useEffect(() => {
    if(data) {
      const _communityBankRates = Object.keys(data)?.filter((key) => ['Yen:', 'Won:', 'Euro', 'Pound'].includes(key)) || [];
      setCommunityBankRates(_communityBankRates);
      const _otherRates = Object.keys(data)?.filter((key) => !['Yen:', 'Won:', 'Euro', 'Pound', "One Dollar buys:", 'One Dollar buys'].includes(key)) || [];
      setOtherRates(_otherRates);
    }
  }, [data]);

  return (
    <>
      <div>
        <div className="sidebar-overlay"></div>
        {/* <SideBar /> */}
        <section className="afn-page-body afn-full-page-body list-body">
          <div className="container-fluid">
            <Link className="radio-header-logo" to="/">
              <img src={AFN_Logo} alt="minimize" class="img-fluid" />
            </Link>

            <>
              <img
                onClick={() => navigate(-1)}
                src={Back}
                alt="minimize"
                class="img-fluid minimize mobile-none"
              />

              <div className="desktop-none header-mobile-menu">
                <img
                  onClick={() => navigate(-1)}
                  src={Back}
                  alt="minimize"
                  class="img-fluid minimize "
                />
                {/* <img src={SearchIcon} alt="minimize" class="img-fluid minimize " /> */}
                <div className="page-head"></div>
              </div>

              <h3 class="list-title stationHeadingText">
                {/* {getName(schedule?.channel_name)} */}
              </h3>


              {loading &&
                <img
                  src={Loader}
                  alt="Loader"
                  style={{ display: loading ? "block" : "none", margin: "auto", width: "50px", height: "50px", marginTop: "50px", marginBottom: '50px'}}
                />
              }
              
              {data && !loading &&
                <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
                  <h3 class="list-title" style={{fontWeight: 'bold', fontSize: 32, margin: 24}}>Exchange Rates</h3>
                  
                  {/* <Text style={{textAlign: 'center', marginBottom: 12}}>Rates provided courtesy Community Bank</Text> */}
                  {communityBankRates?.length > 0 ? 
                    <>
                      <h4 class="list-title" style={{fontSize: 16}}>Rates provided courtesy Community Bank</h4>
                      <h5 class="list-title" style={{marginTop: 8, fontSize: 14}}>One Dollar buys:</h5>
                      <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                        <>
                          {communityBankRates?.map((itemKey, index) => (
                            <div key={itemKey} style={{flex: 1, display: 'flex', alignItems:'center', backgroundColor: '#13173C', marginTop: 8, marginBottom: 8, padding: 16, borderRadius: 4}}>
                              <div style={{flex: 1}}>
                                <div class="list-left-textBox">
                                  {itemKey}
                                </div>
                              </div>
                              <div style={{flex: 1}}>
                                <div class="list-middle-textBox" style={{textAlign: 'end'}}>
                                {typeof data[itemKey] === 'string'
                                  ? `${data[itemKey]}`
                                  : `${data[itemKey]['0']}`}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      </div>
                    </>
                    :
                    null
                  }

                  {otherRates?.length > 0 ? 
                    <>
                      <h4 class="list-title" style={{marginTop: 24, fontSize: 16}}>Other currency rates, not available at the Community Bank</h4>
                      <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                        <>
                          {otherRates?.map((itemKey, index) => (
                            <div key={itemKey} style={{flex: 1, display: 'flex', alignItems:'center', backgroundColor: '#13173C', marginTop: 8, marginBottom: 8, padding: 16, borderRadius: 4}}>
                              <div style={{flex: 1}}>
                                <div class="list-left-textBox">
                                  {itemKey}
                                </div>
                              </div>
                              <div style={{flex: 1}}>
                                <div class="list-middle-textBox" style={{textAlign: 'end'}}>
                                {typeof data[itemKey] === 'string'
                                  ? `${data[itemKey]}`
                                  : `${data[itemKey]['0']}`}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      </div>
                    </>
                    :
                    null
                  }
                </div>
              }
            </>
          </div>
        </section>
      </div>
    </>
  );
};

export default ExchangeRate;


import React from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Home from '../components/Home';
import { AuthProvider, TrackProvider } from '../components/common/context';
import RadioList from '../components/RadioList';
import Radio from '../components/Radio';
import TearmsAndConditions from '../components/termsAndConditions';
import RadioLocations from '../components/radioLocations';
import Podcast from '../components/podcast';
import Omny from '../components/podcast/Omny';
import DVIDS from '../components/podcast/DVIDS';
import Playlist from '../components/Playlist';
import RadioSchedule from '../components/localInfo/RadioSchedule';
import RadioScheduleDetails from '../components/localInfo/RadioScheduleDetails';
import Weather from '../components/localInfo/Weather';
import ExchangeRate from '../components/localInfo/ExchangeRate';
import FuelRate from '../components/localInfo/FuelRate';
import Traffic from '../components/localInfo/Traffic';
import StationRadioPage from '../components/stationRadioPage';
import Footer from '../components/common/footer';
import Listener from '../Listener';
const Navigation = () => {
  return (
    <div className="wrapper">
      <AuthProvider>
        <TrackProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            {/* <Route path='/radioList' element={<RadioList/>} /> */}
            {/* <Route path='/termsAndConditions' element={<TearmsAndConditions/>} /> */}
            {/* <Route path='/radio/:radioName' element={<Radio />} /> */}
            <Route path='/afneurope' element={<RadioLocations />} />
            <Route path='/afnpacific' element={<RadioLocations />} />
            <Route path='/afnpodcast' element={<Podcast />} />
            {/* <Route path='/afnplaylist' element={<Playlist />} /> */}
            <Route path='/afnpodcast/Heard-on-AFN/:programId' element={<Omny />} />
            <Route path='/afnpodcast/military/:unit_name' element={<DVIDS />} />
            <Route path='/afneurope/:radioStation' element={<StationRadioPage />} />
            <Route path='/afnpacific/:radioStation' element={<StationRadioPage />} />
            <Route path='/:region/:radioStation/radio/:radioName' element={<Radio />} />

            <Route path='/afnradioschedule' element={<RadioSchedule/>} />
            <Route path='/afnradioscheduleDetails' element={<RadioScheduleDetails/>} />
            <Route path='/afnweather' element={<Weather/>} />
            <Route path='/afntraffic' element={<Traffic/>} />
            <Route path='/:region/afnexchangerate' element={<ExchangeRate/>} />
            <Route path='/:region/afnfuelrate' element={<FuelRate/>} />
          </Routes>
          <Footer />
          <Listener/>
        </BrowserRouter>
        </TrackProvider>
      </AuthProvider>
    </div>
  );
}

export default Navigation;

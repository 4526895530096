import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Back from "../../res/images/back-icon.svg";
import AFN_Logo from "../../res/images/afn-go-logo.svg";
import _ from "lodash";
import SearchIcon from '../../res/images/search-icon.svg';
import axios from "axios";
import moment from "moment";
import Loader from "../../res/images/Loader.gif";

const Weather = () => {
  const { state } = useLocation()
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState(state?.radioStation || '')
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(searchText) {
      handleSearch()
    }
  }, [])

  const handleSearch = () => {
    setLoading(true)
    axios.get(`https://cms.afngo.net/api/v1/external_info/weather_info?place=${searchText}`)
    .then((res) => {
      // console.log('res.data: ', res?.data)
      setData(res?.data)
      setLoading(false)
    })
    .catch((err) => {
      console.log(err)
      setData(null)
      setLoading(false)
      alert('No data found')
    })
  }

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value.toLowerCase())
  }

  const onSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <>
      <div>
        <div className="sidebar-overlay"></div>
        {/* <SideBar /> */}
        <section className="afn-page-body afn-full-page-body list-body">
          <div className="container-fluid">
            <Link className="radio-header-logo" to="/">
              <img src={AFN_Logo} alt="minimize" class="img-fluid" />
            </Link>

            <>
              <img
                onClick={() => navigate(-1)}
                src={Back}
                alt="minimize"
                class="img-fluid minimize mobile-none"
              />

              <div className="desktop-none header-mobile-menu">
                <img
                  onClick={() => navigate(-1)}
                  src={Back}
                  alt="minimize"
                  class="img-fluid minimize "
                />
                {/* <img src={SearchIcon} alt="minimize" class="img-fluid minimize " /> */}
                <div className="page-head"></div>
              </div>

              <h3 class="list-title stationHeadingText">
                {/* {getName(schedule?.channel_name)} */}
              </h3>

              <div className='desktop-none header-mobile-menu'>
                <div className="page-head">
                  <div className={ "input-group-collapsed input-group"}>
                    <span className="input-group-text">
                      <img src={SearchIcon} alt="ic" className="img-fluid" />
                    </span>
                    <input type="text" onChange={onSearchTextChange} className="form-control" placeholder="Search" onKeyDown={onSearchKeyDown}/>
                    <span onClick={handleSearch} className="input-group-text" style={{backgroundColor: '#262F73', cursor: 'pointer', color: 'white', fontWeight: 'bold'}}>
                      Search
                    </span>
                  </div>
                </div>
              </div>
              <div className="page-head mobile-none">
                <div className="input-group">
                  <span className="input-group-text">
                    <img src={SearchIcon} alt="ic" className="img-fluid" />
                  </span>
                  <input type="text" onChange={onSearchTextChange} className="form-control" placeholder="Search" onKeyDown={onSearchKeyDown}/>
                  <span onClick={handleSearch} className="input-group-text" style={{backgroundColor: '#262F73', cursor: 'pointer', color: 'white', fontWeight: 'bold'}}>
                    Search
                  </span>
                </div>
              </div>

              {loading &&
                <img
                  src={Loader}
                  alt="Loader"
                  style={{ display: loading ? "block" : "none", margin: "auto", width: "50px", height: "50px", marginTop: "50px", marginBottom: '50px'}}
                />
              }
              
              {data && !loading &&
                <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
                  <h3 class="list-title" style={{fontWeight: 'bold', fontSize: 32, margin: 24}}>{data?.location?.name}</h3>
                  <div style={{backgroundImage: 'linear-gradient(#364EC9, #4BB2F0)', display: 'flex', justifyContent: 'space-between', padding: 16, borderRadius: 8, marginBottom: 32, alignItems: 'center'}}>
                    <img src={data?.current?.condition?.icon.replace('//', 'https://')} alt="Weather condition icon" style={{height: 98}}/>
                    <div style={{color: 'white', textAlign: 'end'}}>
                      <div style={{fontWeight: 'bold', fontSize: 36}}>
                        {data?.forecast?.forecastday[0]?.day?.mintemp_f?.toFixed(0)}° ~ {data?.forecast?.forecastday[0]?.day?.maxtemp_f?.toFixed(0)}°
                      </div>
                      <div style={{fontSize: 20}}>
                        Today
                      </div>
                      <div style={{fontSize: 20}}>
                        {moment(data?.forecast?.forecastday[0]?.date)?.format('MMM DD, YYYY')}
                      </div>
                    </div>
                  </div>

                  <div  style={{flex: 1, display: 'flex', alignItems:'center', backgroundColor: '#13173C', marginTop: 8, marginBottom: 8, padding: 16, borderRadius: 4}}>
                    <div style={{flex: 1}}>
                      <div class="list-left-textBox">
                        Day
                      </div>
                    </div>
                    <div style={{flex: 1}}>
                      <div class="list-middle-textBox" style={{textAlign: 'center'}}>
                        Date
                      </div>
                    </div>
                    <div style={{flex: 1}}>
                      <div class="list-left-textBox" style={{textAlign: 'center'}}>
                        Condition
                      </div>
                    </div>
                    <div style={{flex: 1}}>
                      <div class="list-middle-textBox" style={{textAlign: 'center'}}>
                        High
                      </div>
                    </div>
                    <div style={{flex: 1}}>
                      <div class="list-middle-textBox" style={{textAlign: 'end'}}>
                        Low
                      </div>
                    </div>
                  </div>
                  
                  <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                    <>
                      {data?.forecast?.forecastday?.slice?.(1)?.map((item, index) => (
                        <div key={item?.date} style={{flex: 1, display: 'flex', alignItems:'center', backgroundColor: '#13173C', marginTop: 8, marginBottom: 8, padding: 16, borderRadius: 4}}>
                          <div style={{flex: 1}}>
                            <div class="list-left-textBox">
                              {`${moment(item.date).format('dddd')}`}
                            </div>
                          </div>
                          <div style={{flex: 1}}>
                            <div class="list-middle-textBox" style={{textAlign: 'center'}}>
                              {`${moment(item.date).format('MMM DD')}`}
                            </div>
                          </div>
                          <div style={{flex: 1}}>
                            <div class="list-left-textBox" style={{textAlign: 'center'}}>
                              <img src={item?.day?.condition?.icon.replace('//', 'https://')} alt="Weather condition icon" style={{height: 48}}/>
                            </div>
                          </div>
                          <div style={{flex: 1}}>
                            <div class="list-middle-textBox" style={{textAlign: 'center'}}>
                              {item?.day?.maxtemp_f?.toFixed(0)}°F
                            </div>
                          </div>
                          <div style={{flex: 1}}>
                            <div class="list-middle-textBox" style={{textAlign: 'end'}}>
                              {item?.day?.mintemp_f?.toFixed(0)}°F
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  </div>
                </div>
              }
            </>
          </div>
        </section>
      </div>
    </>
  );
};

export default Weather;

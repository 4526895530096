const onPlayerReady = () => {
    console.log("player ready");
    player.addEventListener("track-cue-point", (e)=>console.log(1111,e));
  };
  
  const tdPlayerConfig = {
    coreModules: [
      {
        id: "MediaPlayer",
        playerId: "td_container"
      }
    ],
    playerReady: onPlayerReady
  };
  
  //Player instance
export   const player = new window.TDSdk(tdPlayerConfig);
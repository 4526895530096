import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Back from "../../res/images/back-icon.svg";
import AFN_Logo from "../../res/images/afn-go-logo.svg";
import _ from "lodash";
import SearchIcon from '../../res/images/search-icon.svg';
import axios from "axios";
import moment from "moment";
import Loader from "../../res/images/Loader.gif";

const FuelRate = () => {
  const { state } = useLocation()
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [region, setRegion] = useState(null)
  const location = useLocation()

  useEffect(() => {
    setLoading(true)
    axios.post(`https://cms.afngo.net/api/v1/external_info/fuel_rates_new`)
    .then((res) => {
      // console.log('res.data: ', res?.data)
      if (location.pathname?.toLowerCase()?.includes('/europe')) {
        setRegion('europe')
        setData(res?.data?.europe?.countries)
      }
      if (location.pathname?.toLowerCase()?.includes('/pacific')) {
        setRegion('pacific')
        setData(res?.data?.pacific?.countries)
      }
      setLoading(false)
    })
    .catch((err) => {
      console.log(err)
      setData(null)
      setLoading(false)
      // alert('No data found')
    })
  }, [])

  return (
    <>
      <div>
        <div className="sidebar-overlay"></div>
        {/* <SideBar /> */}
        <section className="afn-page-body afn-full-page-body list-body">
          <div className="container-fluid">
            <Link className="radio-header-logo" to="/">
              <img src={AFN_Logo} alt="minimize" class="img-fluid" />
            </Link>

            <>
              <img
                onClick={() => navigate(-1)}
                src={Back}
                alt="minimize"
                class="img-fluid minimize mobile-none"
              />

              <div className="desktop-none header-mobile-menu">
                <img
                  onClick={() => navigate(-1)}
                  src={Back}
                  alt="minimize"
                  class="img-fluid minimize "
                />
                {/* <img src={SearchIcon} alt="minimize" class="img-fluid minimize " /> */}
                <div className="page-head"></div>
              </div>

              <h3 class="list-title stationHeadingText">
                {/* {getName(schedule?.channel_name)} */}
              </h3>


              {loading &&
                <img
                  src={Loader}
                  alt="Loader"
                  style={{ display: loading ? "block" : "none", margin: "auto", width: "50px", height: "50px", marginTop: "50px", marginBottom: '50px'}}
                />
              }
              
              {data && !loading &&
                <>
                  <h3 class="list-title" style={{fontWeight: 'bold', fontSize: 32, margin: 24}}>Fuel Prices</h3>
                    {data?.map((item, index) => (
                      <div key={item?.country}>
                        {item?.data && !loading && region == 'europe' &&
                          <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
                            <h3 class="list-title" style={{fontSize: 24, marginTop: 32, marginBottom: 8, textTransform: 'none'}}>{item?.country}</h3>
                            
                            <div style={{flex: 1, display: 'flex', alignItems:'center', padding: 12}}>
                              <div style={{flex: 1}}>
                                <div class="list-left-textBox">
                                  Fuel
                                </div>
                              </div>
                              <div style={{flex: 1}}>
                                <div class="list-middle-textBox" style={{textAlign: 'center'}}>
                                  {item?.country?.toLowerCase() == 'belgium' ? 'Quantity' : 'Per Gallon'}
                                </div>
                              </div>
                              <div style={{flex: 1}}>
                                <div class="list-middle-textBox" style={{textAlign: 'center'}}>
                                  {item?.country?.toLowerCase() == 'belgium' ? 'Cost' : 'Per Liter'}
                                </div>
                              </div>
                              <div style={{flex: 1}}>
                                <div class="list-middle-textBox" style={{textAlign: 'end'}}>
                                  Cng.(+/-)
                                </div>
                              </div>
                            </div>

                            <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                              <>
                                {item?.data?.map?.((innerItem, index) => (
                                  <div key={innerItem?.country} style={{flex: 1, display: 'flex', alignItems:'center', backgroundColor: '#13173C', marginBottom: 8, padding: 16, borderRadius: 4}}>
                                    <div style={{flex: 1}}>
                                      <div class="list-left-textBox">
                                        {innerItem?.fuel}
                                      </div>
                                    </div>
                                    <div style={{flex: 1}}>
                                      <div class="list-middle-textBox" style={{textAlign: 'center'}}>
                                        {item?.country?.toLowerCase() == 'belgium' ? innerItem.quantity : innerItem?.per_gallon}
                                      </div>
                                    </div>
                                    <div style={{flex: 1}}>
                                      <div class="list-middle-textBox" style={{textAlign: 'center'}}>
                                        {item?.country?.toLowerCase() == 'belgium' ? innerItem.cost : innerItem.per_liter}
                                      </div>
                                    </div>
                                    <div style={{flex: 1}}>
                                      <div class="list-middle-textBox" style={{textAlign: 'end'}}>
                                        {innerItem[""]}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            </div>
                          </div>
                        }
                        {item?.data && !loading && region == 'pacific' &&
                          <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
                            <h3 class="list-title" style={{fontSize: 24, marginTop: 32, marginBottom: 8, textTransform: 'none'}}>{item?.country}</h3>
                            
                            <div style={{flex: 1, display: 'flex', alignItems:'center', padding: 12}}>
                              <div style={{flex: 1}}>
                                <div class="list-left-textBox">
                                  Fuel
                                </div>
                              </div>
                              <div style={{flex: 1}}>
                                <div class="list-middle-textBox" style={{textAlign: 'end'}}>
                                  Per Gallon
                                </div>
                              </div>
                            </div>

                            <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                              <>
                                {Object.keys(item.data)?.map?.((innerItemKey, index) => (
                                  <div key={index} style={{flex: 1, display: 'flex', alignItems:'center', backgroundColor: '#13173C', marginBottom: 8, padding: 16, borderRadius: 4}}>
                                    <div style={{flex: 1}}>
                                      <div class="list-left-textBox">
                                        {innerItemKey}
                                      </div>
                                    </div>
                                    <div style={{flex: 1}}>
                                      <div class="list-middle-textBox" style={{textAlign: 'end'}}>
                                        {item?.data[innerItemKey]}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            </div>
                          </div>
                        }
                      </div>
                    ))}
                </>
              }
            </>
          </div>
        </section>
      </div>
    </>
  );
};

export default FuelRate;

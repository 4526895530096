import React, { useEffect } from 'react';
import { useAuth, useTrack } from './components/common/context';
import { player } from './components/common/player';
import { csegids, imgList } from './const';
import RadioListData from './radio_station.js'
import { useParams } from 'react-router-dom';
import axios from "axios";
import ReactPlayer from 'react-player'

const Listener = () => {
    const { radio, onSetTrackDetails } = useAuth();
    const region = sessionStorage.getItem('region_name') ? sessionStorage.getItem('region_name') : 'Europe'
    var allRadioList = RadioListData.station.filter((item) => item.region == region)?.[0]?.list
    const { radioName } = useParams()
    var radioIndex = allRadioList?.findIndex(element => element.actual == radioName)
    const {selectedTrack, playerState} = useTrack()

    const updatePlayerTrack = (
        image,
        title,
        subTitle,
        isAlbumArtAvailable,
      ) => {
        onSetTrackDetails({
          title: title,
          subTitle: subTitle,
          albumArtUrl: image?.uri,
          isAlbumArtAvailable: isAlbumArtAvailable,
        });
    };

    const cuePointReceived = (e) => {
        let track = e?.data?.cuePoint?.parameters;
        // console.log(555, track?.cue_title)

        updatePlayerTrack(
            imgList.filter((el) => el?.id == radio?.id)[0]?.name,
            track?.cue_title || `AFN GO ${allRadioList[radioIndex]?.actual}`,
            track?.track_artist_name || allRadioList[radioIndex]?.description,
            false,
        );

        if (
            track.cue_title &&
            track.cue_title != '-' &&
            track.track_artist_name &&
            track.track_artist_name != '-'
        ) {
            axios.get(
                `https://ws.audioscrobbler.com/2.0/?method=album.getinfo&api_key=a488272d159d066505ccc9998b24dce1&artist=${encodeURI(
                track?.track_artist_name,
                )}&album=${encodeURI(e?.data?.cuePoint?.parameters?.cue_title)}&format=json`,
            )
            .then((res) => {
                // console.log('axios.get11', res, res?.data?.album?.image[1]['#text'] && true)
                if (res?.data?.album?.image[2]['#text']) {
                    updatePlayerTrack(
                        {uri: res.data.album.image[2]['#text']},
                        track?.cue_title,
                        track?.track_artist_name,
                        true,
                    );
                } else if (res?.data?.album?.image[1]['#text']) {
                    updatePlayerTrack(
                        {uri: res.data.album.image[1]['#text']},
                        track?.cue_title,
                        track?.track_artist_name,
                        true,
                    );
                } else if (res?.data?.album?.image[0]['#text']) {
                    updatePlayerTrack(
                        {uri: res.data.album.image[0]['#text']},
                        track?.cue_title,
                        track?.track_artist_name,
                        true,
                    );
                } else {
                    updatePlayerTrack(
                        imgList.filter((el) => el?.id == radio?.id)[0]?.name,
                        track?.track_artist_name,
                        track?.cue_title,
                        false,
                    );
                }
            }).catch((err) => {
                console.log(err)
                updatePlayerTrack(
                    imgList.filter((el) => el?.id == radio?.id)[0]?.name,
                    track?.track_artist_name,
                    track?.cue_title,
                    false
                )
            })
        } else {}
    }

    useEffect(() => {
        if (player) {
            player.addEventListener("track-cue-point", cuePointReceived);
        }
    }, []);

    return(
        <>
            {selectedTrack?.triton == undefined &&
                <ReactPlayer style={{display: 'none',}} playing={playerState == 'PLAY'} controls 
                    url={selectedTrack?.file ? [`${selectedTrack?.isSpacialStation ? selectedTrack?.file+"?csegid="+csegids?.[sessionStorage.getItem('location')] : selectedTrack?.file}`] : ''}
                />
            }
        </>
    );
}

export default Listener;

import React, { useState, useEffect } from 'react';
import HeartIcon from '../../res/images/heart-icon-filled.svg';
import DownloadIcon from '../../res/images/download-icon.svg';
import ShareIcon from '../../res/images/share-icon.svg';
import FullScreenIcon from '../../res/images/full-screen-icon.svg';
import { Link, useLocation } from 'react-router-dom';
import TearmsAndConditions from '../termsAndConditions';
import playerImg from '../../res/images/playingImg.png'
import { Image, NavItem, ProgressBar } from 'react-bootstrap';
import { MdOutlineDevicesOther } from 'react-icons/md';
import { FaPlay } from 'react-icons/fa';
import { player } from './player';
import Play from '../../res/images/play-icon.svg';
import PauseIcon from '../../res/images/pause-icon.svg';
import { useAuth, useTrack } from './context';
import { imgList } from '../../const';
import afnlogo from '../../res/images/afn-go-logo.svg';
import { csegids } from '../../const';

const Footer = () => {
    const { radio, onRadioChange, onSetTrackDetails, trackDetails  } = useAuth();
    const [play, setPlay] = useState(false)
    const {pathname} = useLocation()
    const [show, setShow] = useState(false)
    const [radioImg, setRadioImage] = useState('')
    const {onSetSelectedTrack, onSetPlayerState, selectedTrack, playerState} = useTrack()

    const onTrackCuePoint = (e) => {
        if (e?.data?.code == "STREAM_GEO_BLOCKED") {
            setPlay(false)
        }
        if (e.data?.code == "LIVE_PLAYING") {
            setPlay(true)
        }
        if (e.data?.code == "LIVE_STOP") {
            setPlay(false)
        }
    };
  
    useEffect(() => {
        if (player) {
            player.addEventListener("stream-status", onTrackCuePoint);
        }
    }, []);

    const handlePlay = () => {
        // console.log('play', {station: radio.triton, trackingParameters: { csegid: csegids?.[sessionStorage.getItem('location')], country: radio?.isoAlpha2}})
        onRadioChange({item: radio, playState: true})
        player.play({station: radio?.triton, trackingParameters: {csegid: csegids?.[sessionStorage.getItem('location')], country: radio?.isoAlpha2}})
        setPlay(true)
    }

    useEffect(()=>{
        if(radio){
            setRadioImage(imgList.filter((el) => el.id == radio.id)[0]?.name)
        }
    },[radio])

    const updatePlayerTrack = (
        image,
        title,
        subTitle,
        isAlbumArtAvailable,
      ) => {
        onSetTrackDetails({
          title: title,
          subTitle: subTitle,
          albumArtUrl: image?.uri,
          isAlbumArtAvailable: isAlbumArtAvailable,
        });
      };

    const resetTrackDetails = () => {
        updatePlayerTrack(
            imgList.filter((el) => el?.id == radio?.id)[0]?.name,
            `AFN GO ${radio?.actual}`,
            radio?.description,
            false,
        );
    }

    const onPlayPauseTrack = () => {
        /// Reset Radio player ///
        player.stop();
        onSetTrackDetails({});
        onRadioChange({item: null, playState:true})
        /// Reset Radio player ///

        // console.log('selectedTrack?.id', selectedTrack?.id, selectedTrack?.id, playerState, selectedTrack?.id == selectedTrack?.id && playerState == "PLAY")
        if(selectedTrack?.id == selectedTrack?.id && playerState == "PLAY"){
            onSetPlayerState('STOP')
        }else{
            player.play({ station: selectedTrack?.triton, trackingParameters: { csegid: csegids?.[sessionStorage.getItem('location')]} })
            onSetSelectedTrack(selectedTrack)
            onSetPlayerState('PLAY')
        }
    }

    return (
        <>
            {/* <section className="playcard-footer">
                <div className="playcard-left">

                </div>
                <div className="playcard-right">
                    <ul className="card-actions">
                        <li>
                            <img src={HeartIcon} alt="icon" className="img-fluid"/>
                        </li>
                        <li>
                            <img src={DownloadIcon} alt="icon" className="img-fluid"/>
                        </li>
                        <li>
                            <img src={ShareIcon} alt="icon" className="img-fluid"/>
                        </li>
                        <li>
                            <img src={FullScreenIcon} alt="icon" className="img-fluid"/>
                        </li>
                    </ul>
                </div>
            </section> */}

            <TearmsAndConditions show={show} setShow={setShow} />
            <section className="playcard-footer">
                {!pathname.includes('/radio') && radio &&
                    <div className="playerBox">
                        {/* <ProgressBar className="progressLine" now={60} /> */}
                        <div className="playerDetailBox">
                            <div className="playerIcon">
                                <Image src={trackDetails?.albumArtUrl || radioImg || afnlogo} className="img-fluid" />
                            </div>
                            <div className='playerTextBox'>
                                <h5>{trackDetails?.title || radio?.actual} <span>{trackDetails?.subTitle || radio.description}</span></h5>
                                {/* <p><span><MdOutlineDevicesOther /></span>Devices Available</p> */}
                            </div>
                        </div>
                        <div  className="playerControlIcon">
                            {play?
                                <Image src={PauseIcon} onClick={() => {player.stop(); resetTrackDetails(); onRadioChange({item:radio,playState:false})}} />
                                :
                                <Image src={Play} onClick={() =>handlePlay()}/>
                            }
                            {/* <FaPlay/> */}
                        </div>
                    </div>
                }
                {selectedTrack?.id &&
                    <div className="playerBox">
                        {/* <ProgressBar className="progressLine" now={60} /> */}
                        <div className="playerDetailBox">
                            <div className="playerIcon" style={{flex: 0.2, maxHeight: 60, maxWidth: 60}}>
                                <Image src={selectedTrack?.thumbnail || afnlogo} className="img-fluid" />
                            </div>
                            <div className='playerTextBox' style={{flex: 0.8}}>
                                <h5 style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1,  /* number of lines to show */   lineClamp: 1,  WebkitBoxOrient: 'vertical',}}>{selectedTrack?.title} <span>{selectedTrack?.description}</span></h5>
                            </div>
                        </div>
                        <div  className="playerControlIcon">
                            {playerState == "PLAY" ?
                                <Image src={PauseIcon} onClick={onPlayPauseTrack} />
                                :
                                <Image src={Play} onClick={onPlayPauseTrack}/>
                            }
                            {/* <FaPlay/> */}
                        </div>
                    </div>
                }
                <ul className='footer-links'>
                    <li><Link to="/afneurope">AFN Europe</Link></li>
                    <li><Link to="/afnpacific">AFN Pacific</Link></li>
                    <li><a target='_blank' href='https://afngo.freshdesk.com/support/home'>View Help</a></li>
                    <li><a onClick={() => setShow(true)} >Terms & Conditions</a></li>
                </ul>
            </section>
        </>
    );
}

export default Footer;
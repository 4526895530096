import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Back from "../../res/images/back-icon.svg";
import Loader from "../../res/images/Loader.gif";
import AFN_Logo from "../../res/images/afn-go-logo.svg";
import AFNGo from "../../res/images/afn-go-logo.svg";
import axios from "axios";
import _ from "lodash";
import AFN_Legacy from "../../res/images/station-images/AFN_Legacy.png";
import AFN_Fans from "../../res/images/station-images/AFN_Fans.png";
import AFN_Gravity from "../../res/images/station-images/AFN_Gravity.png";
import AFN_Blend from "../../res/images/station-images/AFN-Blend.png";
import AFN_Joe from "../../res/images/station-images/AFN_Joe.png";
import Power_Talk from "../../res/images/station-images/Power-Talk.png";
import AFN_Country from "../../res/images/station-images/AFN_Country.png";
import Voice_logo from "../../res/images/station-images/theVoice_logo.png";

const RadioSchedule = () => {
  const navigate = useNavigate();
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    axios
      .post(`https://cms.afngo.net/api/v1/external_info/myafn`)
      .then((res) => {
        // console.log('res.data: ', res?.data)
        const data = res?.data;

        const lstRemove = ["Clutch", "NPR", 'Clutches', 'PowerTalks'];
        let lstScheduleRadio = _.filter(data, (item) => {
          return !lstRemove.includes(item?.data?.channel_name);
        });
        // console.log("lstScheduleRadio: ", lstScheduleRadio);
        setSchedules(lstScheduleRadio);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
  }, []);

  const getImage = (name) => {
    if (name && name === 'AFN Legacy') {
      return AFN_Legacy
    } else if (name && name === 'Fans') {
      return AFN_Fans
    } else if (name && name === 'Gravity') {
      return AFN_Gravity
    } else if (name && name === 'HOT AC') {
      return AFN_Blend
    } else if (name && name === 'Joe Radio') {
      return AFN_Joe
    } else if (name && name === "Today's Best Country") {
      return AFN_Country
    } else if (name && name === 'PowerTalk') {
      return Power_Talk
    } else if (name && name === 'The Voice') {
      return Voice_logo
    } else {
      return AFNGo
    }
  };

  const getName = (name) => {
    return name
    ? name === "Today's Best Country"
      ? 'Country'
      : name === 'HOT AC'
      ? 'The Blend'
      : name === 'PowerTalk'
      ? 'Power Talk'
      : name
    : ''
  }

  return (
    <>
      <div>
        <div className="sidebar-overlay"></div>
        {/* <SideBar /> */}
        <section className="afn-page-body afn-full-page-body list-body">
          <div className="container-fluid">
            <Link className="radio-header-logo" to="/">
              <img src={AFN_Logo} alt="minimize" class="img-fluid" />
            </Link>

            <>
              <img
                onClick={() => navigate(-1)}
                src={Back}
                alt="minimize"
                class="img-fluid minimize mobile-none"
              />

              <div className="desktop-none header-mobile-menu">
                <img
                  onClick={() => navigate(-1)}
                  src={Back}
                  alt="minimize"
                  class="img-fluid minimize "
                />
                {/* <img src={SearchIcon} alt="minimize" class="img-fluid minimize " /> */}
                <div className="page-head"></div>
              </div>

              <h3 class="list-title stationHeadingText">Select Radio Station</h3>

              {loading &&
                <img
                  src={Loader}
                  alt="Loader"
                  style={{ display: loading ? "block" : "none", margin: "auto", width: "50px", height: "50px", marginTop: "50px", marginBottom: '50px'}}
                />
              }
              
              <div className="radio-list-wrapper desktop-none">
                <>
                  {schedules?.map((item, index) => (
                    <div key={item?.name} className="radio-detail-box" onClick={() => navigate('/afnradioscheduleDetails', {state: {schedule: item}})}>
                      <div
                        className="station-imgBox"
                        style={{ flex: 0.15, maxHeight: 60, maxWidth: 60 }}
                      >
                        <img
                          src={getImage(item?.name)}
                          alt="minimize"
                          class="img-fluid afn-thumbnail"
                        />
                      </div>
                      <div
                        className="radio-detail-contentBox"
                        style={{ flex: 0.8 }}
                      >
                        <h3
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            /* number of lines to show */ lineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {getName(item?.name)}
                        </h3>
                      </div>
                    </div>
                  ))}
                </>
              </div>
              <div className="row radio-list-row mobile-none">
                <>
                  {schedules?.map((item, index) => (
                    <div key={item?.name} className="col-lg-7" onClick={() => navigate('/afnradioscheduleDetails', {state: {schedule: item}})}>
                      <div className="listBox-card">
                        <div className="row align-items-center">
                          <div className="col-lg-2 col-md-2">
                            <div className="stationImg">
                              <img
                                src={getImage(item?.name)}
                                alt="minimize"
                                class="img-fluid afn-thumbnail"
                              />
                            </div>
                          </div>
                          <div className="col-lg-9 col-md-9">
                            <div
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                /* number of lines to show */ lineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                              class="list-left-textBox"
                            >
                              {getName(item?.name)}
                            </div>
                          </div>
                          <div className="col-lg-1 col-md-1">
                            <div class="list-right-textBox"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              </div>
            </>
          </div>
        </section>
      </div>
    </>
  );
};

export default RadioSchedule;

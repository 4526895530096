import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import playIcon from '../res/images/play_arrow.png'
import Back from '../res/images/back-icon.svg'
import AFN_Logo from '../res/images/afn-go-logo.svg'
import AFNGo from '../res/images/afn-go-logo.svg'
import axios from 'axios';
import { useAuth, useTrack } from './common/context';
import PauseIcon from '../res/images/pause-icon.svg';
import { player } from './common/player';
import { csegids } from '../const';
import Loader from '../res/images/Loader.gif';

const spacialStations = [
    {
      id: 'AFN01_S01',
      triton: 'AFN01_S01',
      thumbnail: require('../res/images/playlist/Hall_of_Fame.png'),
      title: 'Hall of Fame',
      description: "#1s of Pop from the 60’s to today",
      file: 'https://27143.live.streamtheworld.com/AFN01_S01AAC.aac',
      isSpacialStation: true
    },
    {
      id: 'AFN02_S01',
      triton: 'AFN02_S01',
      thumbnail: require('../res/images/playlist/On_Stage.png'),
      title: 'On Stage',
      description: "The Best of Live Rock & Roll",
      file: 'https://18303.live.streamtheworld.com/AFN02_S01.mp3',
      isSpacialStation: true
    },
    {
      id: 'AFN03_S01',
      triton: 'AFN03_S01',
      thumbnail: require('../res/images/playlist/The_Groove.png'),
      title: 'The Groove',
      description: "The Definitive Collection of R&B Classics",
      file: 'https://23023.live.streamtheworld.com/AFN03_S01AAC.aac',
      isSpacialStation: true
    },
    {
      id: 'AFN04_S01',
      triton: 'AFN04_S01',
      thumbnail: require('../res/images/playlist/Mach_5.png'),
      title: 'Mach 5',
      description: "House and EDM Beats that Break the Sound Barrier",
      file: 'https://23023.live.streamtheworld.com/AFN04_S01AAC.aac',
      isSpacialStation: true
    },
    {
      id: 'AFN05_S01',
      triton: 'AFN05_S01',
      thumbnail: require('../res/images/playlist/Jazz+.png'),
      title: 'Jazz+',
      description: "A Virtual Lounge of Crooners and Jazz",
      file: 'https://14093.live.streamtheworld.com/AFN05_S01.mp3',
      isSpacialStation: true
    }
  ];

const PlaylistItemList = () => {
    const { programId } = useParams()
    const navigate = useNavigate();
    const {onSetSelectedTrack, onSetPlayerState, selectedTrack, playerState} = useTrack()
    const {onRadioChange, onSetTrackDetails, playing } = useAuth()
    const [isRadioLoading, setIsRadioLoading] = useState(false)

    const onPlayPauseTrack = (item) => {
        /// Reset Radio player ///
        player.stop();
        onSetTrackDetails({});
        onRadioChange({item: null, playState:true})
        /// Reset Radio player ///

        // console.log('selectedTrack?.id', selectedTrack?.id, item?.id, playerState, selectedTrack?.id == item?.id && playerState == "PLAY")
        if(selectedTrack?.id == item?.id && playerState == "PLAY"){
            onSetPlayerState('STOP')
        }else{
            setIsRadioLoading(true)
            onSetSelectedTrack(item)
            onSetPlayerState('PLAY')
            setTimeout(() => {
                player.play({ station: item?.triton, trackingParameters: { csegid: csegids?.[sessionStorage.getItem('location')]} })
                setIsRadioLoading(false)
            }, playing ? 250 : 0);
        }
    }

    return (
        <>
            <div className='radio-list-wrapper desktop-none'>
                {spacialStations?.map((item, index) => {
                    return (
                        <div className='radio-detail-box'>
                            <div className='station-imgBox'>
                                <img src={item?.thumbnail || AFNGo} alt="minimize" class="img-fluid afn-thumbnail" />
                            </div>
                            <div className='radio-detail-contentBox'>
                                <h3 style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1,  /* number of lines to show */   lineClamp: 2,  WebkitBoxOrient: 'vertical',}}>{item?.title} </h3>
                                <p style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1,  /* number of lines to show */   lineClamp: 2,  WebkitBoxOrient: 'vertical',}}>{item?.description}</p>
                            </div>
                            <img src={ (selectedTrack?.id == item?.id && playerState == "PLAY") ? (isRadioLoading ? Loader : PauseIcon) : playIcon} alt=""  style={{height: 16}} className='hoverAbleCol' onClick={()=> onPlayPauseTrack(item)}/>
                        </div>
                    )
                })}
            </div>
            <div className='row radio-list-row mobile-none'>
                <div className='clearfix'></div>

                {spacialStations?.map((item, index) => (
                    <>
                        <div className='col-lg-7' key={index}>
                            <div className="listBox-card">
                                <div className='row align-items-center'>
                                    <div className='col-lg-2 col-md-2'>
                                        <div className='stationImg'>
                                            <img src={item?.thumbnail || AFNGo} alt="minimize" class="img-fluid afn-thumbnail" />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2'>
                                        <div style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2,  /* number of lines to show */   lineClamp: 2,  WebkitBoxOrient: 'vertical',}} class="list-left-textBox">
                                            {item?.title}
                                        </div>
                                    </div>
                                    <div className='col-lg-7 col-md-7'>
                                        <div style={{overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2,  /* number of lines to show */   lineClamp: 2,  WebkitBoxOrient: 'vertical',}} class="list-middle-textBox">
                                            {item?.description}
                                        </div>
                                    </div>
                                    <div className='col-lg-1 col-md-1'>
                                        <div class="list-right-textBox">
                                            {/* <img src={playIcon} alt="" className='hoverAbleCol' onClick={()=> onPlayPauseTrack(item)}/> */}
                                            <img src={ (selectedTrack?.id == item?.id && playerState == "PLAY") ? (isRadioLoading ? Loader : PauseIcon) : playIcon} alt=""  style={{height: 16}} className='hoverAbleCol' onClick={()=> onPlayPauseTrack(item)}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='clearfix'></div>
                    </>
                ))}
            </div>
        </>
    );
}

export default PlaylistItemList;
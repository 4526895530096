import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';

import Minimize from '../res/images/minimize.svg';
import PlayerCover from '../res/images/Incirlik.png';
import afnlogo from '../res/images/afn-go-logo.svg';
import LeftPlay from '../res/images/left-play.svg';
import RightPlay from '../res/images/right-play.svg';
import Play from '../res/images/play-icon.svg';
import PauseIcon from '../res/images/pause-icon.svg';
import Loader from '../res/images/Loader.gif';
import Mute from '../res/images/mute-icon.svg';
import Sound from '../res/images/sound-icon.svg';
import SideBar from './common/sideBar';
import Footer from './common/footer';
import RadioListData from '../radio_station.js'
import Back from '../res/images/back-icon.svg'
import AFN_Logo from '../res/images/afn-go-logo.svg'
import { imgList } from '../const';
import { player as test } from './common/player';
import { useAuth, useTrack } from './common/context';
import Form from 'react-bootstrap/Form'
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"
import axios from "axios";
import { csegids } from '../const';

const Radio = () => {
  const location = useLocation()
  let pathName = decodeURI(location.pathname)
  let region = 'Europe';
  if (pathName.includes('/afneurope')){
    region = 'Europe'
    sessionStorage.setItem('region_name', "Europe")
  }
  else if (pathName.includes('/afnpacific')){
    region = "Pacific"
    sessionStorage.setItem('region_name', "Pacific")
  }
  else if (pathName.includes('News & Sports')){
    region = "News & Sports"
    sessionStorage.setItem('region_name', "News & Sports")
  }

  const { radio, onRadioChange, playing, onSetTrackDetails, trackDetails } = useAuth();
  const navigate = useNavigate()
  const [play, setPlay] = useState(false)
  const [radioIndex, setRadioIndex] = useState('')
  const { radioName, radioStation } = useParams()
  const [radioImg, setRadioImage] = useState('')
  const [geoError, setGeoError] = useState(false)
  const [volume, setVolume] = useState(50)
  const [player, setPlayer] = useState(test)
  const [isRadioLoading, setIsRadioLoading] = useState(false)
  var allRadioList = RadioListData.station.filter((item) => item.region == region)[0].list
  const {onSetSelectedTrack, onSetPlayerState} = useTrack()

  useEffect(() => {
    var index = allRadioList.findIndex(element => element.actual == radioName)
    setRadioIndex(index)
    setRadioImage(imgList.filter((el) => el?.id == allRadioList[index]?.id)[0]?.name)
    if (playing && radioName == radio?.actual) {
      setPlay(true)
    }
  }, [radioName])

  const handleNext = () => {
    player.stop()
    resetTrackDetails()
    setPlay(false)
    var nextObj = allRadioList[radioIndex + 1]
    navigate(`/radio/${nextObj.actual}`)
  }

  const handlePrivious = () => {
    player.stop()
    resetTrackDetails()
    setPlay(false)
    var nextObj = allRadioList[radioIndex - 1]
    navigate(`/radio/${nextObj.actual}`)
  }

  const updatePlayerTrack = (
    image,
    title,
    subTitle,
    isAlbumArtAvailable,
  ) => {
    onSetTrackDetails({
      title: title,
      subTitle: subTitle,
      albumArtUrl: image?.uri,
      isAlbumArtAvailable: isAlbumArtAvailable,
    });
  };

  const resetTrackDetails = () => {
    updatePlayerTrack(
      imgList.filter((el) => el?.id == radio?.id)[0]?.name,
      `AFN GO ${allRadioList[radioIndex]?.actual}`,
      allRadioList[radioIndex]?.description,
      false,
    );
  }

  const onTrackCuePoint = (e) => {
    // console.log(2222, play);
    if (e?.data?.code == "STREAM_GEO_BLOCKED") {
      setGeoError(true)
      setPlay(false)
    }
    if (e.data?.code == "LIVE_PLAYING") {
      setPlay(true)
    }
    if (e.data?.code == "LIVE_STOP") {
      setPlay(false)
    }
  };

  useEffect(() => {
    if (player) {
      player.addEventListener("stream-status", onTrackCuePoint);
    }
  }, []);

  const handlePlay = () => {
    setIsRadioLoading(true)
    /// Reset Previous Track player ///
    onSetSelectedTrack({})
    onSetPlayerState('PLAY')
    /// Reset Previous Track player ///

    onRadioChange({ item: allRadioList[radioIndex], playState: true })
    if (playing) {
      setPlay(false)
      player.stop()
      resetTrackDetails()
    }
    setTimeout(() => {
      player.play({ station: allRadioList[radioIndex]?.triton, trackingParameters: { csegid: csegids?.[sessionStorage.getItem('location')], country: allRadioList[radioIndex]?.isoAlpha2 } })
      setPlay(true)
      player.setVolume(0.5)
      setVolume(50)
      setIsRadioLoading(false)
    }, playing ? 250 : 0);
  }

  const handleVolumeChange = (e) => {
    setVolume(e)
    player.setVolume((e) / 100)
  }

  const handelMuteUnMute = (vol) => {
    setVolume(vol)
    player.setVolume((vol) / 100)
  }

  return (
    <div>
      {geoError &&
        <div className='geoBlockError'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 text-left'>
              <div className='geoBlockErrorText'>

                <h3>AFN Go is not available in your region</h3>
                <p>
                  If you believe you've received this message in error, send your location and your device public IPv4 address to: DMA.AFNGO.HELP@MAIL.MIL
                  Please indicate if you are using a military or civilian network.
                </p>
                <p>
                  You can get your public IPv4 address by searching “find my public IPv4 address” on your device web browser or search engine.
                </p>
                <p>
                  Select “Help” for more information, FAQs, and to submit a trouble ticket directly to the AFN Go Support Desk.
                </p>
                <p>
                  *AFN Go will not work if you are using a VPN to get a US-based IP address.
                </p>
                <div className='geoBlockErrorButtonBox'>
                  <a target="_self" href="https://help.afngo.net/support/home" >
                    <button className='geoBlockErrorButton'>HELP</button>
                  </a>
                  <button className='geoBlockErrorButton' onClick={() => { navigate(-1); }}>BACK</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className="sidebar-overlay"></div>
      {/* <SideBar /> */}

      <section className="afn-page-body afn-full-page-body">
        <div className="afn-player-wrapper">
          <Link className='radio-header-logo' to='/' >
            <img src={AFN_Logo} alt="minimize" className="img-fluid" />
          </Link>
          <div className='radio-header-back'>
            <img onClick={() => { navigate(-1); }} src={Back} alt="minimize" className="img-fluid" />
          </div>

          {/* <img src={Minimize} alt="minimize" className="img-fluid minimize" /> */}
          <div className="player-head">
            <div className='stationRadioImage'>
              <img src={play ? (trackDetails?.albumArtUrl || radioImg || afnlogo ): (radioImg || afnlogo)} alt="cover-image" className="img-fluid cover-image" />

            </div>
            <h2 className="player-title"><span style={{ textTransform: 'uppercase' }}>{play ? (trackDetails?.title || `AFN GO ${allRadioList[radioIndex]?.actual}`) : `AFN GO ${allRadioList[radioIndex]?.actual}`}</span></h2>
            <p className="player-category"> {play ? (trackDetails?.subTitle || allRadioList[radioIndex]?.description) : allRadioList[radioIndex]?.description}
            </p>
          </div>
          <div className="player-track">
            <div className="tracker">
              {/* <span className="track-line"> */}
              {/* <span className="track-progress"></span>
                            <span className="circle"></span> */}
              {/* </span> */}
              {/* <h4 className="time-starts">12:43</h4>
                        <h4 className="time-ends">44:43</h4> */}
            </div>
            <div className="play-actions">
              <button className='next-privious-button' disabled={radioIndex == 0} onClick={() => handlePrivious()} >
                <img src={LeftPlay} alt="icon" className="play-left" />
              </button>
              {isRadioLoading ?
                <span className="play-icon">
                  <img src={Loader} alt="icon" style={{height: 25}} />
                </span>
                : 
                <>
                  {play ?
                    <span className="play-icon"
                      onClick={() => {
                        setPlay(false)
                        onRadioChange({ item: allRadioList[radioIndex], playState: false })
                        player.stop()
                        resetTrackDetails()
                      }}
                    >
                      <img src={PauseIcon} alt="icon" />
                    </span>
                    :
                    <span className="play-icon"
                      onClick={() => {
                        handlePlay()
                      }}
                    >
                      <img src={Play} alt="icon" />
                    </span>
                  }
                </>
              }
              
              <button disabled={radioIndex == allRadioList.length - 1} className='next-privious-button' onClick={() => handleNext()}>
                <img src={RightPlay} alt="icon" className="playcard-right" />
              </button>
            </div>
            <div className="tracker controls">
              <InputRange
                maxValue={100}
                minValue={0}
                value={volume}
                onChange={(e) => handleVolumeChange(e)} />
              {/* <Form.Range value={volume} id='range' className="range-volume" onChange={(e) => handleVolumeChange(e)} /> */}

              {/* <span className="track-line">
                            <span className="track-progress"></span>
                            <span className="circle"></span>
                        </span> */}
              <h4 className="time-starts" onClick={() => handelMuteUnMute(0)}>
                <img src={Mute} alt="" />
              </h4>
              <h4 className="time-ends" onClick={() => handelMuteUnMute(100)}>
                <img src={Sound} alt="" />
              </h4>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
}

export default Radio;
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Back from "../../res/images/back-icon.svg";
import AFN_Logo from "../../res/images/afn-go-logo.svg";
import Loader from "../../res/images/Loader.gif";
import MapMarker from "../../res/images/MapMarker.png";
import GoogleMapReact from 'google-map-react';

const Traffic = () => {
  const navigate = useNavigate();
  const [coords, setCoords] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        if (location) {
          setCoords(location?.coords)
        } else {
          alert('Something went wrong.')
        }
        setLoading(false)
      }, (error) => {
        alert('Please allow location access to use this feature')
        setLoading(false)
      });
    } else {
      alert('Your browser does not support geolocation')
      setLoading(false)
    }
  }, []);

  // console.log('coords: ', coords)

  return (
    <>
      <div>
        <div className="sidebar-overlay"></div>
        {/* <SideBar /> */}
        <section className="afn-page-body afn-full-page-body list-body">
          <div className="container-fluid">
            <Link className="radio-header-logo" to="/">
              <img src={AFN_Logo} alt="minimize" class="img-fluid" />
            </Link>

            <>
              <img
                onClick={() => navigate(-1)}
                src={Back}
                alt="minimize"
                class="img-fluid minimize mobile-none"
              />

              <div className="desktop-none header-mobile-menu">
                <img
                  onClick={() => navigate(-1)}
                  src={Back}
                  alt="minimize"
                  class="img-fluid minimize "
                />
                {/* <img src={SearchIcon} alt="minimize" class="img-fluid minimize " /> */}
                <div className="page-head"></div>
              </div>

              <h3 class="list-title stationHeadingText">Traffic</h3>

              {loading &&
                <img
                  src={Loader}
                  alt="Loader"
                  style={{ display: loading ? "block" : "none", margin: "auto", width: "50px", height: "50px", marginTop: "50px", marginBottom: '50px'}}
                />
              }

              {coords?.latitude && coords?.longitude &&
                <div style={{ height: window?.innerHeight - 300, width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyD2azw7vwAZkFUUQQotKqcXuyKloW335ho" }}
                    center={{
                      lat: coords?.latitude,
                      lng: coords?.longitude
                    }}
                    defaultZoom={14}
                  >
                    <div
                      lat={coords?.latitude}
                      lng={coords?.longitude}
                      text="My Marker"
                    >
                      <img src={MapMarker} alt="marker" />
                    </div>
                  </GoogleMapReact>
                </div>
              }
            </>
          </div>
        </section>
      </div>
    </>
  );
}
export default Traffic;
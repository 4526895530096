import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import SearchIcon from '../res/images/search-icon.svg';
import FamilyIcon from '../res/images/family-logo.png';
import RadioHolidaysBanner from '../res/images/RadioHolidaysBanner.png';
import SideBar from './common/sideBar';
import Footer from './common/footer';
import RadioListData from '../radio_station.js'
import playIcon from '../res/images/play_arrow.png'
import Back from '../res/images/back-icon.svg'
import AFN_Logo from '../res/images/afn-go-logo.svg'
import AFN_thumbnail from '../res/images/afn-go-thumbnail.svg'
import { imgList } from '../const';
import AFNTheEagle from '../res/images/AFNTheEagle.png'
import axios from 'axios';
import moment from "moment";
import { Carousel, CarouselItem } from 'react-bootstrap';
import PlaylistItemList from './PlaylistItemList.jsx';

const StationRadioPage = () => {
    const { radioStation } = useParams()
    const [showInput, setShowInput] = useState(false)
    const [radioList, setRadioList] = useState([])
    const [musicStation, setMusicStation] = useState([])
    const [newsTalkList, setnewsTalkList] = useState([])
    const [banners, setBanners] = useState([])
    const location = useLocation()
    const [region, setRegion] = useState('')
    const navigate = useNavigate();
    // const isRadioStationAvailable = RadioListData?.station?.map((item) => item.list).flat().find((item) => item.actual == radioStation) ? true : false
    const radioStationData = RadioListData?.station?.map((item) => item.list).flat().find((item) => item.actual == radioStation)
    const isHolidayStationAvailable = RadioListData?.station?.[0]?.list?.find((item) => item.actual == 'Holiday') ? true : false

    const handleRadioClick = (id) => {
        navigate(`radio/${id}`);
    }
    const handleRadioClicknewsAndtalk = (id) => {
        navigate(`/News & Sports/${radioStation}/radio/${id}`);
    }

    const handleSearch = (e) => {
        var allRadioList = RadioListData.station.filter((item) => item.region == region)
        var serachRadio = allRadioList[0].list.filter((item) => item.actual.toLowerCase().includes(e.target.value.toLowerCase()))
        setRadioList(serachRadio)
        var newNewsTalkList = RadioListData.station.find(obj => {
            return obj.region === "News & Sports"
        }).list.filter((item) => item.actual.toLowerCase().includes(e.target.value.toLowerCase()))
        setnewsTalkList(newNewsTalkList)
        var newMusicList = allRadioList[0].list.filter((item) => !item.city || item.actual == radioStation).filter((elm) => elm.actual.toLowerCase().includes(e.target.value.toLowerCase()))
        setMusicStation(newMusicList)
    }

    useEffect(() => {
        if (region) {
            var result = RadioListData.station.find(obj => {
                return obj.region === region
            })
            setRadioList(result.list)
            var newMusicList = result.list.filter((item) => !item.city || item.actual == radioStation)
            setMusicStation(newMusicList)
            var newNewsTalkList = RadioListData.station.find(obj => {
                return obj.region === "News & Sports"
            })
            setnewsTalkList(newNewsTalkList.list)

            /// get banner images
            // console.log('radioStationData: ', radioStationData)
            if(radioStationData){
                const _region = region == 'Europe' ? 'Europe' : 'Asia-Pacific'
                axios.get(`https://cms.afngo.net/api/v1/banner_images?region=${_region}&country=${radioStationData?.name}&station=${radioStationData?.actual}&date=${moment().format('YYYY-MM-DD')}&time=${moment().format('HH:mm')}`)
                .then((res) => {
                    console.log('res.data: ', res?.data)
                    setBanners(res?.data)
                })
                .catch((err) => {
                    console.log(err)
                })
            }
            /// get banner images
        }

    }, [region])

    useEffect(() => {
        if (location.pathname.includes('/afneurope')) {
            setRegion('Europe')
        }
        if (location.pathname.includes('/afnpacific')) {
            setRegion('Pacific')
        }
    }, [])

    const playHolidayStation = () => {
        handleRadioClick('Holiday')
    }

    return (
        <>
            <div>
                <div className="sidebar-overlay"></div>
                {/* <SideBar /> */}
                <section className="afn-page-body afn-full-page-body list-body">
                    <div className="container-fluid">
                        <Link className='radio-header-logo' to='/'>
                            <img src={AFN_Logo} alt="minimize" class="img-fluid" />
                        </Link>

                        {radioStationData ?
                            <>
                                <h2 class="player-title mobile-none text-uppercase">Afn {radioStation}</h2>
                                <img onClick={() => navigate(-1)} src={Back} alt="minimize" class="img-fluid minimize mobile-none" />

                                <div className='desktop-none header-mobile-menu'>
                                    <img onClick={() => navigate(-1)} src={Back} alt="minimize" class="img-fluid minimize " />
                                    <h2 class="player-title text-uppercase">AFN {radioStation}</h2>
                                    {/* <img src={SearchIcon} alt="minimize" class="img-fluid minimize " /> */}
                                    <div className="page-head">
                                        <div className={showInput ? "input-group-collapsed input-group" : 'input-group'}>
                                            <span className="input-group-text" onClick={() => setShowInput(!showInput)}>
                                                <img src={SearchIcon} alt="ic" className="img-fluid" />
                                            </span>
                                            <input type="text" onChange={(e) => handleSearch(e)} className="form-control" placeholder="Search" />
                                        </div>
                                    </div>
                                </div>
                                <div className="page-head mobile-none">
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <img src={SearchIcon} alt="ic" className="img-fluid" />
                                        </span>
                                        <input type="text" onChange={(e) => handleSearch(e)} className="form-control" placeholder="Search" />
                                    </div>
                                </div>

                                <div className='radio-list-wrapper desktop-none'>
                                    {isHolidayStationAvailable ?
                                        <div className='radio-detail-box' onClick={playHolidayStation} style={{justifyContent: 'center', padding: 4}}>
                                            <img src={RadioHolidaysBanner} alt="minimize" style={{height: '100%'}}/>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className='radio-detail-box' onClick={() => navigate('/afnPodcast')} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Podcasts</h4>
                                    </div>
                                </div>
                                {/* <div className='radio-list-wrapper desktop-none'>
                                    <div className='radio-detail-box' onClick={() => navigate('/afnPlaylist')} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Playlists</h4>
                                    </div>
                                </div> */}

                                <div className='radio-list-wrapper hoverAbleCol mobile-none'>
                                    {isHolidayStationAvailable ?
                                        <div className='radio-detail-box col-lg-7 mx-auto' onClick={playHolidayStation} style={{justifyContent: 'center', padding: 4}}>
                                            <img src={RadioHolidaysBanner} alt="minimize" style={{height: '100%'}}/>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className='radio-detail-box col-lg-7 mx-auto' onClick={() => navigate('/afnPodcast')} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Podcasts</h4>
                                    </div>
                                </div>

                                {/* <div className='radio-list-wrapper hoverAbleCol mobile-none'>
                                    <div className='radio-detail-box col-lg-7 mx-auto' onClick={() => navigate('/afnPlaylist')} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Playlists</h4>
                                    </div>
                                </div> */}

                                <h3 class="list-title mobile-none stationHeadingText">Music Stations</h3>

                                <div className='radio-list-wrapper desktop-none'>
                                    <h3 class="list-title stationHeadingText">Music Stations</h3>
                                    {musicStation?.length > 0 ?
                                        <>
                                            {musicStation?.map((item, index) => {
                                                return (

                                                    <div className='radio-detail-box' onClick={(e) => handleRadioClick(item.actual)}>
                                                        <div className='station-imgBox'>
                                                            {index === 0 ?
                                                                <img src={AFNTheEagle} alt="minimize" class="img-fluid afn-thumbnail" />
                                                                :
                                                                <img src={imgList.filter((el) => el.id == item.id)[0]?.name || AFN_Logo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                            }
                                                        </div>
                                                        <div className='radio-detail-contentBox'>
                                                            <h3>{item.actual} </h3>
                                                            <p>{item.name}</p>
                                                        </div>
                                                        <img src={playIcon} alt="" />
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <span className='NotFountText'>Station Not Found</span>
                                    }
                                    <PlaylistItemList />
                                    <h3 class="list-title stationHeadingText">NEWS & SPORTS</h3>
                                    {newsTalkList?.length > 0 ?
                                        <>
                                            {newsTalkList?.map((item, index) => {
                                                return (

                                                    <div className='radio-detail-box' onClick={(e) => handleRadioClick(item.actual)}>
                                                        <div className='station-imgBox'>
                                                            <img src={imgList.filter((el) => el.id == item.id)[0]?.name || AFN_Logo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                        </div>
                                                        <div className='radio-detail-contentBox'>
                                                            <h3>{item.actual} </h3>
                                                            <p>{item.name}</p>
                                                        </div>
                                                        <img src={playIcon} alt="" />
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <span className='NotFountText'>Station Not Found</span>
                                    }
                                    <h3 class="list-title stationHeadingText">EAGLE STATIONS</h3>
                                    {radioList?.length > 0 ?
                                        <>
                                            {radioList?.filter((item) => item.city)?.map((item, index) => {
                                                return (

                                                    <div className='radio-detail-box' onClick={(e) => handleRadioClick(item.actual)}>
                                                        <div className='station-imgBox'>
                                                            <img src={imgList.filter((el) => el.id == item.id)[0]?.name || AFN_Logo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                        </div>
                                                        <div className='radio-detail-contentBox'>
                                                            <h3>{item.actual} </h3>
                                                            <p>{item.name}</p>
                                                        </div>
                                                        <img src={playIcon} alt="" />
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <span className='NotFountText'>Station Not Found</span>
                                    }
                                </div>
                                <div className=' mobile-none'>
                                    <div className='row radio-list-row'>
                                        {musicStation?.length > 0 ?
                                            <>
                                                <div className='col-lg-7 mobile-none'>
                                                    <div className="listBox-header">
                                                        <div className='row'>
                                                            <div className='col-lg-2 col-md-2'>

                                                            </div>
                                                            <div className='col-lg-2 col-md-2'>
                                                                <div class="list-left-textBox">
                                                                    Station
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-7 col-md-7'>
                                                                <div class="list-middle-textBox">
                                                                    Description
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='clearfix'></div>

                                                {musicStation?.map((item, index) => (
                                                    <>
                                                        <div className='col-lg-7 hoverAbleCol' key={index} onClick={(e) => handleRadioClick(item.actual)}>
                                                            <div className="listBox-card">
                                                                <div className='row align-items-center'>
                                                                    <div className='col-lg-2 col-md-2'>
                                                                        <div className='stationImg'>
                                                                            {index === 0 ?
                                                                                <img src={AFNTheEagle} alt="minimize" class="img-fluid afn-thumbnail" />
                                                                                :
                                                                                <img src={imgList.filter((el) => el.id == item.id)[0]?.name || AFN_Logo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-2 col-md-2'>
                                                                        <div class="list-left-textBox">
                                                                            {item.actual}
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-7 col-md-7'>
                                                                        <div class="list-middle-textBox">
                                                                            {item.description}
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-1 col-md-1'>
                                                                        <div class="list-right-textBox">
                                                                            <img src={playIcon} alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='clearfix'></div>
                                                    </>
                                                ))}
                                            </>
                                            :
                                            <span className='NotFountText'>Station Not Found</span>
                                        }
                                    </div>
                                    <PlaylistItemList />
                                </div>
                                <h3 class="list-title mobile-none newListHeading stationHeadingText"> NEWS & SPORTS </h3>
                                <div className='row radio-list-row mobile-none'>
                                    {newsTalkList?.length > 0 ?
                                        <>
                                            <div className='col-lg-7 mobile-none'>
                                                <div className="listBox-header">
                                                    <div className='row'>
                                                        <div className='col-lg-2 col-md-2'>

                                                        </div>
                                                        <div className='col-lg-2 col-md-2'>
                                                            <div class="list-left-textBox">
                                                                Station
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-7 col-md-7'>
                                                            <div class="list-middle-textBox">
                                                                Description
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='clearfix'></div>

                                            {newsTalkList?.map((item, index) => (
                                                <>
                                                    <div className='col-lg-7 hoverAbleCol' key={index} onClick={(e) => handleRadioClicknewsAndtalk(item.actual)}>
                                                        <div className="listBox-card">
                                                            <div className='row align-items-center'>
                                                                <div className='col-lg-2 col-md-2'>
                                                                    <div className='stationImg'>
                                                                        <img src={imgList.filter((el) => el.id == item.id)[0]?.name || AFN_Logo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-2'>
                                                                    <div class="list-left-textBox">
                                                                        {item.actual}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-7 col-md-7'>
                                                                    <div class="list-middle-textBox">
                                                                        {item.description}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-1 col-md-1'>
                                                                    <div class="list-right-textBox">
                                                                        <img src={playIcon} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </>
                                            ))}
                                        </>
                                        :
                                        <span className='NotFountText'>Station Not Found</span>
                                    }

                                </div>
                                <h3 class="list-title mobile-none newListHeading stationHeadingText"> Eagle Stations </h3>
                                <div className='row radio-list-row mobile-none'>
                                    {radioList?.length > 0 ?
                                        <>
                                            <div className='col-lg-7 mobile-none'>
                                                <div className="listBox-header">
                                                    <div className='row'>
                                                        <div className='col-lg-2 col-md-2'>

                                                        </div>
                                                        <div className='col-lg-2 col-md-2'>
                                                            <div class="list-left-textBox">
                                                                Station
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-7 col-md-7'>
                                                            <div class="list-middle-textBox">
                                                                Description
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='clearfix'></div>

                                            {radioList?.filter((item) => item.city).map((item, index) => (
                                                <>
                                                    <div className='col-lg-7 hoverAbleCol' key={index} onClick={(e) => handleRadioClick(item.actual)}>
                                                        <div className="listBox-card">
                                                            <div className='row align-items-center'>
                                                                <div className='col-lg-2 col-md-2'>
                                                                    <div className='stationImg'>
                                                                        <img src={imgList.filter((el) => el.id == item.id)[0]?.name || AFN_Logo} alt="minimize" class="img-fluid afn-thumbnail" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-2'>
                                                                    <div class="list-left-textBox">
                                                                        {item.actual}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-7 col-md-7'>
                                                                    <div class="list-middle-textBox">
                                                                        {item.description}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-1 col-md-1'>
                                                                    <div class="list-right-textBox">
                                                                        <img src={playIcon} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='clearfix'></div>
                                                </>
                                            ))}
                                        </>
                                        :
                                        <span className='NotFountText'>Station Not Found</span>
                                    }
                                </div>

                                <h3 class="list-title mobile-none newListHeading stationHeadingText"> Local Info </h3>
                                <div className='radio-list-wrapper desktop-none'>
                                    <div className='radio-detail-box' onClick={() => navigate('/afnradioschedule')} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Radio Schedules</h4>
                                    </div>
                                </div>
                                <div className='radio-list-wrapper desktop-none'>
                                    <div className='radio-detail-box' onClick={() => navigate('/afnweather', {state:{radioStation: radioStation}})} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Weather</h4>
                                    </div>
                                </div>
                                <div className='radio-list-wrapper desktop-none'>
                                    <div className='radio-detail-box' onClick={() => navigate('/afntraffic')} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Traffic</h4>
                                    </div>
                                </div>
                                <div className='radio-list-wrapper desktop-none'>
                                    <div className='radio-detail-box' onClick={() => navigate(`/${region}/afnexchangerate`)} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Exchange Rates</h4>
                                    </div>
                                </div>
                                <div className='radio-list-wrapper desktop-none'>
                                    <div className='radio-detail-box' onClick={() => navigate(`/${region}/afnfuelrate`)} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Fuel Prices</h4>
                                    </div>
                                </div>

                                <div className='radio-list-wrapper hoverAbleCol mobile-none'>
                                    <div className='radio-detail-box col-lg-7 mx-auto' onClick={() => navigate('/afnradioschedule')} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Radio Schedules</h4>
                                    </div>
                                </div>
                                <div className='radio-list-wrapper hoverAbleCol mobile-none'>
                                    <div className='radio-detail-box col-lg-7 mx-auto' onClick={() => navigate('/afnweather', {state:{radioStation: radioStation}})} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Weather</h4>
                                    </div>
                                </div>
                                <div className='radio-list-wrapper hoverAbleCol mobile-none'>
                                    <div className='radio-detail-box col-lg-7 mx-auto' onClick={() => navigate('/afntraffic')} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Traffic</h4>
                                    </div>
                                </div>
                                <div className='radio-list-wrapper hoverAbleCol mobile-none'>
                                    <div className='radio-detail-box col-lg-7 mx-auto' onClick={() => navigate(`/${region}/afnexchangerate`)} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Exchange Rates</h4>
                                    </div>
                                </div>
                                <div className='radio-list-wrapper hoverAbleCol mobile-none'>
                                    <div className='radio-detail-box col-lg-7 mx-auto' onClick={() => navigate(`/${region}/afnfuelrate`)} style={{justifyContent: 'center'}}>
                                        <h4 style={{color: 'white'}}>Fuel Prices</h4>
                                    </div>
                                </div>

                                {banners && banners.length > 0 && 
                                    <Carousel controls={false} interval={3000} wrap={true} pause={'hover'} className='col-lg-7 mx-auto' style={{display: 'flex', justifyContent: 'center', maxWidth: window?.innerWidth}}>
                                        {banners?.map((item, index) => (
                                            <CarouselItem onClick={() => window.open(item?.link, '_blank').focus()}>
                                                <img src={item?.image} style={{cursor: 'pointer', width: '100%', marginBottom: 48}}/>
                                            </CarouselItem>
                                        ))}
                                    </Carousel>
                                }
                            </>
                            :
                            <>
                                <img onClick={() => navigate(-1)} src={Back} alt="minimize" class="img-fluid minimize" style={{marginTop: 40}} />
                                <h2 class="player-title" style={{marginTop: 40}}>404 | Radio Station Not Found</h2>
                            </>

                        }

                    </div>
                    
                </section>

                {/* <Footer /> */}
            </div>


        </>
    );
}

export default StationRadioPage;